import { Link } from "react-router-dom";

const PostEventGallery = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>

                {/* <!--Page Header Start--> */}
                <section className="page-header member-section">
                    <div className="page-header__bg post-event-gallery-banner">
                    </div>
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Post Event Gallery</h2>
                            <div className="thm-breadcrumb__box">
                                <ul className="thm-breadcrumb list-unstyled">
                                    {/* <li><a href="index.html">Home</a></li> */}
                                    <li><Link to="/">Home</Link></li>
                                    <li><span className="icon-angle-left"></span></li>
                                    <li>Post Event Gallery</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* //   <!--Page Header End--> */}

                {/* <!--Project One Start--> */}
                <section class="project-one">
                    <div class="container">
                        <div class="section-title text-center">
                            <div class="section-title__tagline-box">
                                <span class="section-title__tagline">Our Gallery</span>
                            </div>
                            <h2 class="section-title__title">Let’s discover all our <br />recent project</h2>
                        </div>
                        <div class="row masonary-layout">
                            {/* <!--Project One Single Start--> */}
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                <Link to="#">
                                    <div class="project-one__single">
                                        <div class="project-one__img-box">
                                            <div class="project-one__img">
                                                <img src="images/resources/conferences-1.webp" alt="" />
                                            </div>
                                            <div class="project-one__content">
                                                <p class="project-one__sub-title">Delhi</p>
                                                <h3 class="project-one__title">
                                                    <Link to="#">Event 1</Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--Project One Single End--> */}
                            {/* <!--Project One Single Start--> */}
                            {/* <div class="col-xl-3 col-lg-6 col-md-6">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/project-1-2.jpg" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title"><a href="project-details.html">Delivering success
                                                through logistics</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!--Project One Single End--> */}
                            {/* <!--Project One Single Start--> */}
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                <Link to="">
                                    <div class="project-one__single">
                                        <div class="project-one__img-box">
                                            <div class="project-one__img">
                                                <img src="images/resources/conferences-2.webp" alt="" />
                                            </div>
                                            <div class="project-one__content">
                                                <p class="project-one__sub-title">Delhi</p>
                                                <h3 class="project-one__title">
                                                    <Link to="#">Event 2</Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--Project One Single End--> */}
                            {/* <!--Project One Single Start--> */}
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                <Link to="">
                                    <div class="project-one__single">
                                        <div class="project-one__img-box">
                                            <div class="project-one__img">
                                                <img src="images/resources/conferences-3.webp" alt="" />
                                            </div>
                                            <div class="project-one__content">
                                                <p class="project-one__sub-title">Delhi</p>
                                                <h3 class="project-one__title">
                                                    <Link to="#">Event 3</Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--Project One Single End--> */}
                            {/* <!--Project One Single Start--> */}
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                <Link to="#">
                                    <div class="project-one__single">
                                        <div class="project-one__img-box">
                                            <div class="project-one__img">
                                                <img src="images/resources/conferences-4.webp" alt="" />
                                            </div>
                                            <div class="project-one__content">
                                                <p class="project-one__sub-title">Delhi</p>
                                                <h3 class="project-one__title">
                                                    <Link to="#">Event 4</Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--Project One Single End--> */}
                            {/* <!--Project One Single Start--> */}
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                <Link to="">
                                    <div class="project-one__single">
                                        <div class="project-one__img-box">
                                            <div class="project-one__img">
                                                <img src="images/resources/conferences-5.webp" alt="" />
                                            </div>
                                            <div class="project-one__content">
                                                <p class="project-one__sub-title">Delhi</p>
                                                <h3 class="project-one__title">
                                                    <Link to="project-details.html">Event 5</Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--Project One Single End--> */}
                            {/* <!--Project One Single Start--> */}
                            {/* <div class="col-xl-3 col-lg-6 col-md-6">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/project-1-7.jpg" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title"><a href="project-details.html">Delivering success
                                                through logistics</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!--Project One Single End--> */}
                            {/* <!--Project One Single Start--> */}
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <Link to="#">
                                    <div class="project-one__single">
                                        <div class="project-one__img-box">
                                            <div class="project-one__img">
                                                <img src="images/resources/conferences-7.webp" alt="" />
                                            </div>
                                            <div class="project-one__content">
                                                <p class="project-one__sub-title">Delhi</p>
                                                <h3 class="project-one__title">
                                                    <Link to="#">Event 6</Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--Project One Single End--> */}
                            {/* <!--Project One Single Start--> */}
                            <div class="col-xl-3 col-lg-6 col-md-6">
                                <Link to="#">
                                    <div class="project-one__single">
                                        <div class="project-one__img-box">
                                            <div class="project-one__img">
                                                <img src="images/resources/conferences-6.webp" alt="" />
                                            </div>
                                            <div class="project-one__content">
                                                <p class="project-one__sub-title">Delhi</p>
                                                <h3 class="project-one__title"><Link to="#">Event 7</Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--Project One Single End--> */}
                        </div>
                    </div>
                </section>
                {/* <!--Project One End--> */}

                <div class="st-team-area pt-100 pb-100">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="st__section__title style2 mb-50 text-center wow fadeInUp">
                                    <h5> Gallery</h5>
                                    <span class="circle"></span>
                                    <h1>Meet The Excecutive Panel</h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                                <div class="st__team__box text-center">
                                    <div class="st__team__thumb">
                                        <img src="images/resources/conferences-8.png" alt="" />
                                    </div>
                                    <div class="st__team__content">
                                        <Link to="#">
                                            <h2> Conferences 1</h2>
                                            <span> Noida </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                                <div class="st__team__box text-center">
                                    <div class="st__team__thumb">
                                        <img src="images/resources/conferences-9.webp" alt="" />
                                    </div>
                                    <div class="st__team__content">
                                        <Link to="#">
                                            <h2> Conferences 2 </h2>
                                            <span> Noida </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                                <div class="st__team__box text-center">
                                    <div class="st__team__thumb">
                                        <img src="images/resources/conferences-11.png" alt="" />
                                    </div>
                                    <div class="st__team__content">
                                        <Link to="#">
                                            <h2> Conferences 3 </h2>
                                            <span> Noida </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                                <div class="st__team__box text-center">
                                    <div class="st__team__thumb">
                                        <img src="images/resources/conferences-10.webp" alt="" />
                                    </div>
                                    <div class="st__team__content">
                                        <Link to="#">
                                            <h2> Conferences 4 </h2>
                                            <span> Noida </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Footer Component Call */}

                {/* footer-form */}

                {/* <Footer /> */}
            </div>
            <div className="mobile-nav__wrapper">
                <div className="mobile-nav__overlay mobile-nav__toggler"></div>
                {/* <!-- /.mobile-nav__overlay --> */}
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

                    <div className="logo-box">
                        <a href="/" aria-label="logo image"><img src="images/resources/logo-1wn.png" width="150"
                            alt="" /></a>
                    </div>
                    {/* <!-- /.logo-box --> */}
                    <div className="mobile-nav__container"></div>
                    {/* <!-- /.mobile-nav__container --> */}

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <a href="mailto:needhelp@packageName__.com">info@oneworldnetwork.co</a>
                        </li>
                        <li>
                            <i className="fa fa-phone-alt"></i>
                            <a href="tel:+971 54 522 3903">+971 54 522 3903</a>
                        </li>
                    </ul>
                    {/* <!-- /.mobile-nav__contact --> */}
                    <div className="mobile-nav__top">
                        <div className="mobile-nav__social">
                            <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-facebook-square"></a>
                            <a href="#" className="fab fa-pinterest-p"></a>
                            <a href="#" className="fab fa-instagram"></a>
                        </div>
                        {/* <!-- /.mobile-nav__social --> */}
                    </div>
                    {/* <!-- /.mobile-nav__top --> */}



                </div>
                {/* <!-- /.mobile-nav__content --> */}
            </div>
            {/* // <!-- /.mobile-nav__wrapper --> */}

            <div className="search-popup">
                <div className="search-popup__overlay search-toggler"></div>
                {/* <!-- /.search-popup__overlay --> */}
                <div className="search-popup__content">
                    <form action="#">
                        <label for="search" className="sr-only">search here</label>
                        {/* <!-- /.sr-only --> */}
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" className="thm-btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </form>
                </div>
                {/* <!-- /.search-popup__content --> */}
            </div>
        </>
    )
}

export default PostEventGallery
