import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";

import RECAPTCHA from "react-google-recaptcha";
import FormikControl from "components/wrapperComponents/FormikControl";
import {
  Box,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Button,
} from "@mui/material";
import { Typography } from "@mui/material";
import EventRegistrationSchema from "schemas/eventRegistartion/EventRegistrationSchema";
import { EventRegistrationModel } from "model/screensModels/eventRegistration/EventRegistrationModel";
import { msgTypes } from "constants/msgTypes";
import { EventService } from "services/menu/event/EventService";
import { UploadFileService } from "services/shared/UploadFileService";
import { UtilService } from "services/shared/UtilService";
import AttendeeForm from "./AttendeeForm";

const EventRegistrationForm = () => {
  const [captchaMatched, setCaptchaMatched] = useState(false);
  const [activeStep, setActiveStep] = useState(0); // State to manage active step
  const [initialValue, setInitialValue] = useState(new EventRegistrationModel());
  const [attendeeList, setAttendeeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const recaptcha = useRef()

  // Initial form values

  const validationSchema = EventRegistrationSchema;
  const noOfParicipants = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "6", value: "6" },
    { key: "7", value: "7" },
    { key: "8", value: "8" },
    { key: "9", value: "9" },
    { key: "10", value: "10" },
    { key: "11", value: "11" },
  ]

  useEffect(() => {
    loadCountry();
    loadEventList();
  }, [])


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //load country
  const loadCountry = async () => {
    const res = await UtilService.getCountryList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCountryList(res.data);
    }
  }

  //load state list
  const loadStateList = async (countryId) => {
    const res = await UtilService.getStateList(countryId);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setStateList(res.data);
    }
  }

  //load City list
  const loadCityList = async (stateId) => {
    const res = await UtilService.getCityList(stateId);
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setCityList(res.data);
    }
  }

  //load Event list
  const loadEventList = async () => {
    const res = await EventService.getEventList();
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setEventList(res.data);
    }
  }




  const onCaptchaSubmit = (value) => {
    if (value)
      setCaptchaMatched(true)
    else
      setCaptchaMatched(false)
  }

  //Prepare Data for API
  const prepareRequestBody = async (values: EventRegistrationModel) => {
    const prepareData = new EventRegistrationModel();

    prepareData.eventId = values.eventId;

    attendeeList.map(async (data) => {
      //to get image url
      if (data.formalPhoto) {
        const formData = new FormData();
        formData.append("file", data.formalPhoto);
        const imageurlData = await UploadFileService.getImagePath(formData);
        data.formalPhoto = imageurlData.data.fileName;
        prepareData.attendees.push(data);
      } else {
        data.formalPhoto = '';
      }
    });

    prepareData.companyName = values?.companyName;
    prepareData.companyEmail = values?.companyEmail;
    prepareData.primaryPhoneNumber = values.primaryPhoneNumber;

    prepareData.secondaryPhoneNumber = values.secondaryPhoneNumber;
    prepareData.websiteUrl = values.websiteUrl;
    prepareData.memberStatus = values.memberStatus;

    prepareData.addressLine1 = values.addressLine1;
    prepareData.city = values.city;
    prepareData.state = values.state;
    prepareData.country = values.country;
    prepareData.zipCode = values.zipCode;

    prepareData.companyDetails = values.companyDetails;
    prepareData.quantityOfParticipants = values.quantityOfParticipants;

    prepareData.companyBoothType = values.companyBoothType;
    prepareData.companyHotelReservation = values.companyHotelReservation;
    prepareData.companySponsorship = values.companySponsorship;

    prepareData.termsAndCondition = values.termsAndCondition;


    //to get image url
    if (values.companyLogo) {
      const formData = new FormData();
      formData.append("file", values.companyLogo);
      const imageurlData = await UploadFileService.getImagePath(formData);
      prepareData.companyLogo = imageurlData.data.fileName;
    } else {
      prepareData.companyLogo = '';
    }

    return prepareData;

  }

  const submitEvent = async (values) => {
    const requestData = await prepareRequestBody(values);
    const res = await EventService.eventRegistration(requestData)
    if (res.statusMsg === msgTypes.SUCCESS.msg) {
      setActiveStep(0);
      setAttendeeList([]);
      setInitialValue(new EventRegistrationModel())
      return true
    }
    return false;
  }

  return (
    <Box
      className="main-box"
      width="100%"
      height="100%"
      margin="0 auto"
      padding="20px"
    >
      <Grid container justifyContent="center">
        <Paper
          className="event-paper"
          style={{
            width: "100%",
            padding: "20px",
            overflow: "auto",
            marginBottom: "200px"
          }}
        >
          <Typography variant="h3" align="center" marginBottom="30px">
            Event Registration Form
          </Typography>

          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema[activeStep]}
            onSubmit={async (values, { resetForm }) => {
              // setSubmitting(false);
              if (activeStep < validationSchema.length - 1) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
              const res = await submitEvent(values);
              if (res)
                resetForm();

            }}
          >
            {({ handleSubmit, isSubmitting, values, isValid, dirty, setFieldValue, setFieldTouched }) => (
              <form onSubmit={handleSubmit}>

                <Stepper
                  activeStep={activeStep}
                  // orientation={isSmallScreen ? 'vertical' : 'horizontal'}
                  sx={{
                    width: '80%',
                    backgroundColor: 'transparent',
                    marginBottom: '13px',
                    fontWeight: '300',
                    margin: '5 auto',
                    fontSize: '1.5rem' // Adjust the font size for larger labels
                  }}
                >
                  {[
                    'Select Event',
                    'Attendees',
                    'Company Details',
                    'Company Address',
                    'Others Details',
                  ].map((label, index) => (
                    <Step key={index}>
                      <StepLabel
                        sx={{
                          '& .MuiStepLabel-label': {
                            fontSize: '1.0rem', // Adjust the font size for larger labels
                            fontWeight: 'bold',
                            textTransform: 'uppercase'
                          }
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {/* <Stepper
                  activeStep={activeStep}
                  sx={{
                    width: "100%",
                    backgroundColor: "transparent",
                    marginBottom: "30px",
                  }}
                >
                  <Step>
                    <StepLabel>Select Event</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Attendees</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Company Details</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Company Address</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Others Details</StepLabel>
                  </Step>
                </Stepper> */}

                {/* Render form fields based on the active step */}
                <Box className="event-reg-form" width="80%" margin="0 auto" padding="20px" marginBottom='60px'>
                  <Grid container justifyContent="left">
                    {activeStep === 0 && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormikControl control='autocomplete'
                              label="Select Event"
                              name="eventId"
                              placeholder="Select Event"
                              requiredField
                              options={eventList}
                              keyfield="eventName"
                              valuefield="eventId"
                            />
                          </Grid>
                        </Grid>
                        <Box marginTop="20px" display="flex" justifyContent="space-between">
                          <Button
                            className='btn btn-submit'
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={(!dirty || !(isValid))}
                          >
                            Next
                          </Button>
                        </Box>
                      </>
                    )}
                    {activeStep === 1 && (
                      <>
                        {/* call Attende Form */}
                        <AttendeeForm attendeeList={attendeeList} setAttendeeList={setAttendeeList} />
                        {attendeeList?.length > 0 ?
                          <Box marginTop="20px" display="flex" justifyContent="space-between">
                            <Button
                              className='btn btn-back backBtn'
                              variant="contained"
                              color="primary"
                              onClick={handleBack}
                            > Back</Button>
                            <Button
                              className='btn btn-submit'
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              disabled={(!dirty || !(isValid) || attendeeList?.length === 0)}
                            > Next</Button>
                          </Box>
                          : ""}
                      </>
                    )}

                    {activeStep === 2 && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="textfield"
                              name="companyName"
                              label="Company Name"
                              placeholder="Enter Your Company Name"
                              requiredField
                              disabled={false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="textfield"
                              name="companyEmail"
                              label="Company Email"
                              placeholder="Enter Your Company Email"
                              requiredField
                              disabled={false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="textfield"
                              name="primaryPhoneNumber"
                              label="Mobile Number"
                              placeholder="Enter Your Mobile Number"
                              requiredField
                              disabled={false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="textfield"
                              name="secondaryPhoneNumber"
                              label="Alternative Mobile Number"
                              placeholder="Enter Your Alternative Mobile Number"
                              disabled={false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="textfield"
                              name="websiteUrl"
                              label="URL"
                              placeholder="URL"
                              disabled={false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl className="border-input"
                              control="radiogroup"
                              name="memberStatus"
                              label="1 WN Member"
                              disabled={false}
                              row
                              options={[
                                { key: "Yes", value: true },
                                { key: "No", value: false },
                              ]}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <FormikControl
                              control="textfield"
                              name="companyDetails"
                              label="Company Details"
                              placeholder="Enter Your Company Details"
                              rows={4}
                              requiredField
                              multiline
                              disabled={false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} className="file-input">
                            <FormikControl
                              control="uploadImage"
                              label="Logo"
                              requiredField
                              name="companyLogo"
                            // disabled={viewMode}
                            />
                          </Grid>
                        </Grid>
                        <Box marginTop="20px" display="flex" justifyContent="space-between">
                          <Button
                            className='btn btn-back backBtn'
                            variant="contained"
                            color="primary"
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                          <Button
                            //type="submit"
                            className='btn btn-submit'
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={(!dirty || !(isValid)) || values.companyName.length === 0}
                          >
                            Next
                          </Button>
                        </Box>
                      </>
                    )}

                    {activeStep === 3 && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="textfield"
                              name="addressLine1"
                              label="Address"
                              placeholder="Enter Your Address"
                              requiredField
                              disabled={false}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl control='autocomplete'
                              label="Country"
                              name="country"
                              placeholder="Select Country"
                              requiredField
                              options={countryList}
                              onChange={(e) => { loadStateList({ "country": e.countryIdentifier }) }}
                              keyfield="name"
                              valuefield="countryIdentifier"
                            />


                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormikControl control='autocomplete'
                              label="State"
                              name="state"
                              placeholder="Select State"
                              requiredField
                              options={stateList}
                              onChange={(e) => { loadCityList({ "state": e.stateIdentifier }) }}
                              keyfield="name"
                              valuefield="stateIdentifier"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormikControl control='autocomplete'
                              label="City"
                              name="city"
                              placeholder="Select City"
                              requiredField
                              options={cityList}
                              onChange={(e) => { loadCityList({ "state": e.stateIdentifier }) }}
                              keyfield="name"
                              valuefield="stateIdentifier"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="textfield"
                              name="zipCode"
                              label="Zipcode"
                              placeholder="Enter Your Zipcode"
                              requiredField
                              disabled={false}
                            />
                          </Grid>
                        </Grid>

                        <Box marginTop="20px" display="flex" justifyContent="space-between">
                          <Button
                            className='btn btn-back backBtn'
                            variant="contained"
                            color="primary"
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                          <Button
                            //type="submit"
                            className='btn btn-submit'
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={(!dirty || !(isValid)) || values.addressLine1.length === 0}
                          >
                            Next
                          </Button>
                        </Box>
                      </>
                    )}

                    {activeStep === 4 && (
                      <>
                        <Grid container spacing={2}>

                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="radiogroup"
                              name="companyBoothType"
                              label="Booth Type"
                              requiredField
                              column
                              disabled={false}
                              options={[
                                { key: "3m*3m Booth: USD 4500", value: "3m*3m Booth: USD 4500" },
                                { key: "6m*3m Booth: USD 9000", value: "6m*3m Booth: USD 9000" },
                                { key: "6m*6m Booth: USD 18000", value: "6m*6m Booth: USD 18000" },
                                { key: "Photo Booth: USD 2500", value: "Photo Booth: USD 2500" },
                                { key: "Fixed Table: USD 2500", value: "Fixed Table: USD 2500" },
                                { key: "None of the above", value: "None of the above" },
                              ]}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="radiogroup"
                              name="companyHotelReservation"
                              label="Hotel Reservation"
                              requiredField
                              column
                              disabled={false}
                              options={[
                                { key: "Superior Room King Bed: USD 210/Night", value: "Superior Room King Bed: USD 210/Night" },
                                { key: "Delux Room King Bed: USD 230/Night ", value: "Delux Room King Bed: USD 230/Night" },
                                { key: "None of the above", value: "None of the above" },
                              ]}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormikControl
                              control="select"
                              name="quantityOfParticipants"
                              label="Quantity Of Participants"
                              requiredField
                              disabled={false}
                              options={noOfParicipants}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <RECAPTCHA
                              sitekey={msgTypes.GOOGLE_CAPTCHA_KEY}
                              ref={recaptcha}
                              onChange={onCaptchaSubmit}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <FormikControl
                              control="ckeckbox"
                              name="termsAndCondition"
                              label="Click To Agree To Terms And Conditions"
                              requiredField
                              disabled={false}
                            />
                          </Grid>
                        </Grid>
                        <Box mt={2} display="flex" justifyContent="space-between">
                          {activeStep > 0 ?
                            <Button
                              className='btn btn-back backBtn'
                              variant="contained"
                              color="primary"
                              onClick={handleBack}
                            >
                              Back
                            </Button>
                            : ""}

                          {activeStep === validationSchema.length - 1 ?
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={!isValid || !dirty || !captchaMatched || values.termsAndCondition === false}
                            >Submit</Button> : ""}
                        </Box>
                      </>
                    )}
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Box>
  );
};

export default EventRegistrationForm;
