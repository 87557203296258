import { RouteType } from "constants/RouteType";
import { NewsletterSubscribers } from "model/screensModels/newsletterSubscribers/NewsletterSubscribers";
import { AuthService } from "services/auth/AuthService";

export class NewsletterSubscribersService {
    constructor() { }
  
    static saveNewsletterSubscriber = async (requestData: NewsletterSubscribers) => {
      const url = RouteType.NEWSLETTERS_SUBSCRIBERS;
      const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestData))
      return response;
    };

}