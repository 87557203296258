export class ContactPersonModel{
    name: string;
    position: string;
    phoneNumber: string;
    email: string;
    contactPersonImage: string;
    contactIsrepresentative: boolean;

    constructor(){
        this.name="";
        this.position="";
        this.phoneNumber="";
        this.email="";
        this.contactPersonImage="";
        this.contactIsrepresentative = false;
    }
}