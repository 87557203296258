import { Link } from "react-router-dom"

const PrivacyPolicies = () => {
    return (
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header member-section">
                <div className="page-header__bg privacy-policy-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Privacy Policies</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Privacy Policies</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}

            {/* <!-- -------Content start------ --> */}
            <section class="policy-privacy">
                <div class="container">
                    <h1>Privacy Policy</h1>
                </div>

                <div class="container">
                    <h2 class="police-heading">STRAIGHFORWARD & LUCID PROFESSIONAL RELATIONSHIP IS WHAT WE VOUCH ON.</h2>
                    <p class="police-paragraph">This Privacy Policy informs a member about his / her rights to privacy and
                        legal
                        protections, as well as
                        Our policies and processes regarding the collection, use, and sharing of their information when they
                        use
                        the Service.</p>
                    <p class="police-paragraph">We utilize a member’s Personal information to deliver and enhance the
                        Service.
                        Your consent to the
                        collection and use of information in line with this Privacy Policy by using the Service.</p>
                    <h2 class="police-heading">You will be informed of the following by it</h2>
                    <ol type="a">
                        <li>What personally identifiable information does the website gather about you, how is it utilized,
                            and who might get access to it?</li>
                        <li>In terms of how your data is used, what options do you have?</li>
                        <li>The security measures put in place to guard against information misuse.
                        </li>
                        <li>The steps a member may take to update any inaccurate information.</li>
                    </ol>
                    <h2 class="police-heading">Introduction</h2>
                    <p class="police-paragraph">Welcome to One World Network. We are dedicated to safeguarding your privacy
                        and
                        securing your personal data. This Privacy Policy outlines how we collect, use, share, and protect
                        your
                        personal information when you use our website at <a href="#">www.oneworldnetwork.co</a></p>
                    <h2 class="police-heading">Data Controller</h2>
                    <p class="police-paragraph">The data controller responsible for your personal data is:</p>
                    <h5>One World Network FZCO,</h5>
                    <p>Address: <span class="cookie-text"> 38130-001, IFZA Business Park, Dubai Silicon Oasis, Dubai,
                        UAE.</span>
                    </p>
                    <p>Email: <span class="cookie-text">info@oneworldnetwork.co</span>
                    </p>
                    <h2 class="police-heading">Information Gathering, Utilization, and Exchanger</h2>
                    <ul>
                        <li>The information gathered on this website belongs to us alone.</li>
                        <li>We only have access to and will gather information from you that you willingly provide us
                            through
                            email or other direct communication.</li>
                        <li>This information will not be rented or sold to third parties.</li>
                        <li>In order to reply to you and address the reason you contacted us, we will use the information
                            you
                            provide.</li>
                        <li>Your information won't be shared with any parties outside of our company unless it's absolutely
                            required to fulfil your request, like shipping an item.</li>
                        <li>We may email you in the future to notify you about promotions, new goods or services, or
                            modifications to this privacy statement, unless you specifically request that we not do so.</li>
                        <li>Information Access and Control Are Up to You.</li>
                        <li>Usage Data is collected automatically when using the Service.</li>
                        <li>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
                            address), browser type, browser version, the pages of our Service that You visit, the time and
                            date
                            of Your visit, the time spent on those pages, unique device identifiers and other diagnostic
                            data.
                        </li>
                        <li>When You access the Service by or through a mobile device, we may collect certain information
                            automatically, including, but not limited to, the type of mobile device You use, your mobile
                            device
                            unique ID, the IP address of Your mobile device, your mobile operating system, the type of
                            mobile
                            Internet browser You use, unique device identifiers and other diagnostic data.</li>
                        <li>We may also collect information that Your browser sends whenever You visit our Service or when
                            You
                            access the Service by or through a mobile device.</li>
                    </ul>
                    <p class="police-paragraph">You are free to unsubscribe from any future communications at any time.</p>
                    <p class="police-paragraph">By getting in touch with us at the phone number or email address provided on
                        our
                        website, you can do the
                        following at any time: </p>
                    <ul>
                        <li>Check to see if we have any information about you.</li>
                        <li>Modify or update any information we may have about you.</li>
                        <li>Request that we erase all of our records of you.</li>
                        <li>Let us know if you have any concerns about how we are using your data.</li>
                    </ul>
                    <h2 class="police-heading">Cookies and Tracking Technologies</h2>
                    <p class="police-paragraph">We track user behaviour on Our Service and retain certain data using Cookies
                        and
                        other tracking
                        technologies. Beacons, tags, and scripts are examples of tracking technologies that are used to
                        gather
                        and monitor data in order to enhance and evaluate Our Service. Among the technologies We might
                        employ
                        are:</p>
                    <ul>
                        <li><span class="cookie-text">Cookies or Browser Cookies: </span> A little file called a cookie is
                            stored
                            on your device. You can set your browser to notify you when a cookie is being sent or to reject
                            all
                            cookies. On the other hand, You might not be able to utilize certain sections of our Service if
                            You
                            reject Cookies. Our Service may use cookies unless you have changed your browser's settings to
                            reject cookies.</li>
                        <li><span class="cookie-text">Web beacons: </span>Web beacons, also known as clear gifs, pixel tags,
                            and
                            single-pixel gifs, are tiny electronic files that are sometimes included in email messages and
                            certain parts of our service. They allow the company to track user activity on those pages,
                            count
                            the number of people who have visited those pages, and gather other website-related data (like
                            tracking how popular a particular section is and ensuring system and server integrity).</li>
                    </ul>
                    <p class="police-paragraph">We use both Session and Persistent Cookies for the purposes set out below:
                    </p>
                    <h2>Essential Cookies</h2>
                    <p class="police-paragraph"><span class="cookie-text">Type: </span>Session Cookies</p>
                    <p class="police-paragraph"><span class="cookie-text">Administered by: </span>Us</p>
                    <p class="police-paragraph"><span class="cookie-text">Goal: </span>These Cookies are necessary for You
                        to be
                        able to access some of
                        the Website's features and for You to receive services through it. They aid in user authentication
                        and
                        guard against unauthorized usage of user accounts. The services You have requested cannot be
                        delivered
                        without these Cookies, and We only use these Cookies in order to fulfil Your request.</p>
                    <h2 class="police-heading">Functionality Cookies</h2>
                    <p class="police-paragraph"><span class="cookie-text">Type: </span>Persistent Cookies</p>
                    <p class="police-paragraph"><span class="cookie-text">Administered by: </span>Us</p>
                    <p class="police-paragraph"> The goal of these cookies is to help us remember decisions You make when
                        using
                        the website, like your
                        preferred language or login information. These Cookies are meant to make your experience on the
                        website
                        more tailored to you and to save you from having to re-enter your settings each time you visit.</p>
                    <p class="police-paragraph">Cookies, web beacons, and other comparable tracking technologies might be
                        used
                        by us to improve your
                        experience on our site. Through the settings of your browser, you may control your cookie choices.
                    </p>
                    <h2 class="police-heading">Data Retention</h2>
                    <p class="police-paragraph">The Company will retain Your Personal Data only for as long as is necessary
                        for
                        the purposes set out in
                        this Privacy Policy. To the extent required to fulfil our legal duties (for instance, if we must
                        keep
                        your data on file to abide by applicable laws), settle disputes, and uphold our legal agreements and
                        policies, we will keep and utilize Your Personal Data.</p>
                    <p class="police-paragraph">Additionally, the Company will also retain Usage Data for internal analysis
                        purposes. Generally speaking,
                        usage data is only kept for a limited amount of time, unless it is needed to bolster the security or
                        enhance the performance of Our Service, or unless We are required by law to keep it longer.</p>
                    <h2 class="police-heading">Sharing of personal data
                    </h2>

                    <h5 class="police-heading">Purpose of Data Usage</h5>
                    <ol>
                        <li>To maintain and provide our Service, including monitoring usage.
                        </li>
                        <li>To manage Your Account: registration and access to Service functionalities.</li>
                        <li>For contractual obligations: managing purchase contracts and other agreements.</li>
                        <li>Contacting You: via email, phone calls, or electronic communication for updates and
                            notifications.
                        </li>
                        <li>Providing news, offers, and information about goods and services.
                        </li>
                        <li>Managing Your requests and inquiries.
                        </li>
                        <li>Evaluating or conducting business transfers, mergers, or acquisitions.
                        </li>
                        <li>Other purposes such as data analysis, improving services, and marketing effectiveness.
                        </li>
                    </ol>
                    <h5 class="police-heading">Data Sharing Situations</h5>
                    <ol>
                        <li>With Service Providers: for monitoring, analysis, and contacting.
                        </li>
                        <li>During business transfers: merger, sale, financing, or acquisition.</li>
                        <li>With Affiliates: sharing with affiliated companies.</li>
                        <li>With business partners: offering products, services, or promotions.
                        </li>
                        <li>Public interactions: information shared in public areas may be viewed by all users.
                        </li>
                        <li>With Your consent: disclosure for any other purpose with Your consent.
                        </li>
                    </ol>
                    <h2 class="police-heading">Transfer of Your Individual/Personal Information</h2>
                    <p class="police-paragraph">The Company processes your information, including Personal Data, at its
                        operating offices and any other
                        locations where the parties engaged in the processing have their offices. This implies that this
                        data
                        may be moved to and kept on computers situated outside of Your nation, state, or other governmental
                        jurisdiction, where the regulations governing data protection may be different from those in Your
                        jurisdiction.</p>
                    <p class="police-paragraph">Your agreement to the transfer is shown by your acceptance of this privacy
                        policy and your submission of
                        such information.</p>
                    <p class="police-paragraph">The Company will take all reasonably required actions to guarantee that Your
                        data is handled securely and
                        in compliance with this Privacy Policy. Your Personal Data will not be transferred to another
                        organization or nation unless sufficient safeguards, such as those pertaining to the security of
                        Your
                        data and other personal information, are in place.</p>
                    <h2 class="police-heading">Retention of personal Data </h2>
                    <p class="police-paragraph">Your Personal Data will only be kept by the Company for the duration
                        required
                        to fulfil the objectives
                        outlined in this Privacy Policy. To the extent required to fulfil our legal duties (for instance, if
                        we
                        must keep your data on file to abide by applicable laws), settle disputes, and uphold our legal
                        agreements and policies, we will keep and utilize Your Personal Data.</p>
                    <p class="police-paragraph">Additionally, Usage Data will be kept by the Company for internal analysis.
                        Generally speaking, usage
                        data is only kept for a limited amount of time, unless it is needed to bolster the security or
                        enhance
                        the performance of Our Service, or unless We are required by law to keep it longer.</p>
                    <h2 class="police-heading">Delete Your Personal Information</h2>
                    <p class="police-paragraph">You possess the entitlement to expunge or solicit Our assistance in deleting
                        the
                        Personal Data that We
                        have gathered concerning You.</p>
                    <p class="police-paragraph">You might be able to remove certain information about yourself from the
                        Service
                        using our service.</p>
                    <p class="police-paragraph">By logging into Your Account, if you have one, and going to the account
                        settings
                        section where you can
                        manage Your personal information, you can edit, amend, or delete Your information at any time. You
                        can
                        also get in touch with us to ask to see, update, or remove any personal data you've given us.</p>
                    <p class="police-paragraph">Please be aware, though, that in cases where we are required by law or have
                        a
                        legitimate reason to do so,
                        we might need to keep certain information.</p>
                    <h2 class="police-heading">Disclosure of Your Personal Data in Business Activities</h2>
                    <p class="police-paragraph">In the event that the Company engages in a merger, acquisition, or asset
                        sale,
                        your personal data may be
                        transferred. We will give you notice before Your Personal Data is transferred and becomes the
                        subject of
                        a new privacy policy.</p>
                    <h2 class="police-heading">Law Enforcement</h2>
                    <p class="police-paragraph">The Company may be compelled by law to reveal Your Personal Data in specific
                        situations, or in response
                        to legitimate requests made by public authorities (such as a court or government agency).
                    </p>
                    <h2 class="police-heading">Further legal requirements</h2>
                    <p class="police-paragraph">If the Company has a good faith belief that disclosing Your Personal Data is
                        required to: </p>
                    <ul>
                        <li>Adhere to a legal obligation</li>
                        <li>Protect and defend the Company's rights and property</li>
                        <li>Stop or look into any potential wrongdoing related to the Service</li>
                        <li>Safeguard the public's or Service Users' personal safety </li>
                        <li>Shield against legal liability.</li>
                    </ul>
                    <h2 class="police-heading">Security of personal Data </h2>
                    <p class="police-paragraph">Although We care about the security of Your Personal Data, keep in mind that
                        no
                        method of electronic data
                        transmission over the Internet or technique of electronic data storage is 100% safe. We make every
                        effort to safeguard Your Personal Data using methods that are financially feasible, but we are
                        unable to
                        p ensure complete security.
                    </p>
                    <h2 class="police-heading">Usage of Google Analytics: </h2>
                    <p class="police-paragraph">Google, Inc. ("Google") offers Google Analytics as a web analytics service.
                        Cookies and related
                        technologies are used by Google Analytics to track website activity and analyse how you use the
                        site.
                        Kindly refer to Google's Privacy Policy for additional details regarding Google Apalytics and its
                        data
                        practices.</p>
                    <p class="police-paragraph">The Google Analytics Opt-Out Browser Add-on, which is accessible here:
                        Google
                        Analytics Opt-Out, allows
                        you to opt out of Google Analytics.</p>
                    <p class="police-paragraph">Although We care about the security of Your Personal Data, keep in mind that
                        no
                        method of electronic data
                        transmission over the Internet or technique of electronic data storage is 100% safe. We make every
                        effort to safeguard Your Personal Data using methods that are financially feasible, but we are
                        unable to
                        ensure complete security.</p>
                    <h2 class="police-heading">Security of Data</h2>
                    <p class="police-paragraph">To safeguard your information, we take security measures. Your information
                        is
                        secure both online and off
                        when you submit sensitive data through the website.</p>
                    <p class="police-paragraph">Anywhere that we gather sensitive data (such credit card numbers), the data
                        is
                        encrypted and sent to us
                        securely. You can check for this by either looking for HTTPS// at the start of the web page's
                        address or
                        for a closed lock icon at the bottom of your browser.</p>
                    <p class="police-paragraph">We safeguard your information offline in addition to protecting sensitive
                        data
                        transmitted online via
                        encryption. Personally identifiable information is only accessible to personnel who require it to
                        carry
                        out a specific task (like billing or customer assistance). Our servers and PCs that house personally
                        identifiable data pre housed in a secure environment.
                    </p>
                    <h2 class="police-heading">Links/Connection to different Websites</h2>
                    <p class="police-paragraph">There may be links within Our Service to external websites that are not run
                        by
                        Us. A third party's
                        website will be displayed if you click on one of their links. We strongly suggest that you go over
                        each
                        website's privacy statement.</p>
                    <p class="police-paragraph">The content, privacy practices, and policies of any third-party websites or
                        services are beyond our
                        control and responsibility.</p>
                    <h2 class="police-heading">Surveys & Competitions</h2>
                    <p class="police-paragraph">TOur website occasionally asks for information through surveys or
                        competitions.
                        You are under no
                        obligation to engage in any surveys or contests, and you are free to decline participation and the
                        disclosure of personal information. Contact details, such as name and shipping address, as well as
                        demographic data, including zip code and age range, may be asked. To contact the winners and deliver
                        the
                        rewards, contact details will be required. The information gathered from surveys will be used to
                        track
                        and enhance how this site is used and enjoyed.</p>
                    <h2 class="police-heading">Changes to this Privacy Policy</h2>
                    <p class="police-paragraph">We might occasionally make changes to Our Privacy Policy. Before the change
                        takes effect, we will notify
                        You by email and/or by posting a noticeable notice on Our Service. We will also update the "Last
                        updated" date at the top of this Privacy Policy.</p>
                    <p class="police-paragraph">It is recommended that you routinely check this Privacy Policy for updates.
                        Any
                        modifications to this
                        privacy statement take effect immediately upon posting on this website.</p>
                    <h2 class="police-heading">Contact Us</h2>
                    <p class="police-paragraph">If you have any questions about this Privacy Policy, you can contact us:</p>
                    <p class="police-paragraph"><span class="cookie-text">By email :</span><a
                        href="#">info@oneworldnetwork.co</a>
                    </p>
                    <p class="police-paragraph"><span class="cookie-text">By phone number :</span><a
                        href="#">+91-7303733554</a>
                    </p>

                </div>
            </section>
        </>
    )
}

export default PrivacyPolicies