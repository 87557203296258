import { Link } from "react-router-dom"

const CookiePolicies = () => {
    return (
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header member-section">
                <div className="page-header__bg cookie-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Cookie Policies</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Cookie Policies</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}

            {/* <!-- -------Content start------ --> */}
            <section class="policy-privacy">
                <div class="container">
                    <p>Before using the One World Network website, take a moment to review this, Cookie Policy. Your use of
                        our website implies your agreement with these terms. If you do not agree, kindly refrain from using
                        our site. This policy is provided by One World Network (Company).</p>
                </div>

                <div class="container">
                    <h1 class="police-heading">INTRODUCTION</h1>
                    <ul class="pr-ul">
                        <li>
                            <span class="icon"><i class="fas fa-check"></i></span>
                            <span class="text">
                                No registration is required to access our website.
                            </span>
                        </li>
                        <li>
                            <span class="icon"><i class="fas fa-check"></i></span>
                            <span class="text">
                                Your complete acceptance of this Cookie Policy is assumed when accessing any part of our
                                site. If you do not agree with the policy, please exit the website promptly.
                            </span>
                        </li>
                        <li>
                            <span class="icon"><i class="fas fa-check"></i></span>
                            <span class="text">
                                To stay informed, we may update this Cookie Policy by posting changes. Check back
                                periodically for updates.
                            </span>
                        </li>
                    </ul>
                    <h4 class="police-heading">COOKIES USAGE</h4>
                    <p>By using our website, you are giving consent to the use of cookies as outlined in this policy.</p>
                    <p>We utilize cookies for the following purposes:</p>
                    <ul class="pr-ul">
                        <li>
                            <span class="icon"><i class="fas fa-arrow-right"></i></span>
                            <span class="text">
                                Personalizing your user experience
                            </span>
                        </li>
                        <li>
                            <span class="icon"><i class="fas fa-arrow-right"></i></span>
                            <span class="text">
                                Enhancing website functionality.
                            </span>
                        </li>
                        <li>
                            <span class="icon"><i class="fas fa-arrow-right"></i></span>
                            <span class="text">
                                Analysing user navigation and behaviour.
                            </span>
                        </li>
                    </ul>
                    <p class="police-paragraph">You can manage your cookie preferences through your browser settings.
                        However, disabling cookies may impact your ability to access certain features.</p>
                    <h2 class="police-heading">COOKIE MANAGEMENT</h2>
                    <p class="police-paragraph">Take control of your cookie preferences through your browser settings.</p>
                    <p class="police-paragraph">Information on managing or deleting cookies can be found at [insert link
                        to browser-specific information].</p>
                    <h2 class="police-heading">THIRD-PARTY COOKIES</h2>
                    <p class="police-paragraph">We may incorporate third-party services using cookies. Note that these
                        cookies are subject to the privacy policies of the respective third parties.
                    </p>
                    <h2 class="police-heading">UPDATES TO THE POLICY</h2>
                    <p class="police-paragraph">Technology and regulations may prompt updates to this Cookie Policy.
                        Regularly check for any changes.</p>
                    <h2 class="police-heading">CONTACT</h2>
                    <p class="police-paragraph">Reach out to us at [provide contact information] for questions or concerns
                        about this Cookie Policy.</p>
                    <h2 class="police-heading">GOVERNING LAW AND JURISDICTION</h2>
                    <p class="police-paragraph">This Cookie Policy is governed by [Your Country/Region] law.</p>


                </div>
            </section>
        </>
    )
}

export default CookiePolicies