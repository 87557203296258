export class AttendeeModel{
    name: string;
    gender: string;
    position: string;
    phoneNumber: string;
    emailId: string;
    formalPhoto: string;
    foodType: string;
    sponsorshipNeeded: boolean;

    constructor(){
        this.name= "";
        this.gender= "mr.";
        this.position= "";
        this.phoneNumber= "";
        this.emailId= "";
        this.formalPhoto= "";
        this.foodType= "";
        this.sponsorshipNeeded= false;
    }
}