import { Link } from "react-router-dom"

const CSR = () => {
    return (
        <>

            {/* <!--Page Header Start--> */}
            <section className="page-header">
                <div className="page-header__bg csr-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>CSR</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>CSR</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}


            <section class="blog-grid">
                <div class="container">
                    <div class="row">
                        {/* <!--CSR Grid Single Start--> */}
                        <div class="col-xl-6 col-lg-6">
                            <div class="blog-grid__single">
                                <div class="blog-grid__img-box">
                                    <a href="/csr-details">
                                        <div class="blog-grid__img">
                                            <img src="images/csr/csr-1.png" alt="" />
                                        </div>
                                    </a>
                                </div>
                                <div class="blog-grid__content-box">
                                    <div class="blog-grid__meta-box">
                                        <div class="blog-grid__date">
                                            <p>22 Feb. 2022</p>
                                        </div>
                                        <ul class="list-unstyled blog-grid__meta">
                                            <li><a href="#">By : Admin</a></li>
                                            <li><a href="#">repairing, maintenance</a></li>
                                        </ul>
                                    </div>
                                    <div class="blog-grid__content">
                                        <h3 class="blog-grid__title"><a href="/csr-details">How to Hire a Machanic for
                                            Appliances Fix</a></h3>
                                        <p class="blog-grid__text">Our objective at assimox is to bring together our
                                            visitor's societies and spirits with our own, communicating enthusiasm and
                                            liberality in the food we share.</p>
                                        <div class="blog-one__read-more">
                                            <a href="/csr-details">Read More<span class="icon-arrow-right"></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--CSR Grid Single End--> */}
                        {/* <!--CSR Grid Single Start--> */}
                        <div class="col-xl-6 col-lg-6">
                            <div class="blog-grid__single">
                                <div class="blog-grid__img-box">
                                    <a href="/csr-details">
                                        <div class="blog-grid__img">
                                            <img src="images/csr/csr-2.png" alt="" />
                                        </div>
                                    </a>
                                </div>
                                <div class="blog-grid__content-box">
                                    <div class="blog-grid__meta-box">
                                        <div class="blog-grid__date">
                                            <p>22 Feb. 2022</p>
                                        </div>
                                        <ul class="list-unstyled blog-grid__meta">
                                            <li><a href="#">By : Admin</a></li>
                                            <li><a href="#">repairing, maintenance</a></li>
                                        </ul>
                                    </div>
                                    <div class="blog-grid__content">
                                        <h3 class="blog-grid__title"><a href="/csr-details">It is a long established
                                            fact that a reader</a></h3>
                                        <p class="blog-grid__text">Our objective at assimox is to bring together our
                                            visitor's societies and spirits with our own, communicating enthusiasm and
                                            liberality in the food we share.</p>
                                        <div class="blog-one__read-more">
                                            <a href="/csr-details">Read More<span class="icon-arrow-right"></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--CSR Grid Single End--> */}
                        {/* <!--CSR Grid Single Start--> */}
                        <div class="col-xl-6 col-lg-6">
                            <div class="blog-grid__single">
                                <div class="blog-grid__img-box">
                                    <a href="/csr-details">
                                        <div class="blog-grid__img">
                                            <img src="images/csr/csr-3.png" alt="" />
                                        </div>
                                    </a>
                                </div>
                                <div class="blog-grid__content-box">
                                    <div class="blog-grid__meta-box">
                                        <div class="blog-grid__date">
                                            <p>22 Feb. 2022</p>
                                        </div>
                                        <ul class="list-unstyled blog-grid__meta">
                                            <li><a href="#">By : Admin</a></li>
                                            <li><a href="#">repairing, maintenance</a></li>
                                        </ul>
                                    </div>
                                    <div class="blog-grid__content">
                                        <h3 class="blog-grid__title"><a href="/csr-details">The point of using Lorem
                                            Ipsum is that it</a></h3>
                                        <p class="blog-grid__text">Our objective at assimox is to bring together our
                                            visitor's societies and spirits with our own, communicating enthusiasm and
                                            liberality in the food we share.</p>
                                        <div class="blog-one__read-more">
                                            <a href="/csr-details">Read More<span class="icon-arrow-right"></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--CSR Grid Single End--> */}
                        {/* <!--CSR Grid Single Start--> */}
                        <div class="col-xl-6 col-lg-6">
                            <div class="blog-grid__single">
                                <div class="blog-grid__img-box">
                                    <a href="/csr-details">
                                        <div class="blog-grid__img">
                                            <img src="images/csr/csr-4.png" alt="" />
                                        </div>
                                    </a>
                                </div>
                                <div class="blog-grid__content-box">
                                    <div class="blog-grid__meta-box">
                                        <div class="blog-grid__date">
                                            <p>22 Feb. 2022</p>
                                        </div>
                                        <ul class="list-unstyled blog-grid__meta">
                                            <li><a href="#">By : Admin</a></li>
                                            <li><a href="#">repairing, maintenance</a></li>
                                        </ul>
                                    </div>
                                    <div class="blog-grid__content">
                                        <h3 class="blog-grid__title"><a href="/csr-details">Best Repairing for Leakage
                                            Roof In Locality</a></h3>
                                        <p class="blog-grid__text">Our objective at assimox is to bring together our
                                            visitor's societies and spirits with our own, communicating enthusiasm and
                                            liberality in the food we share.</p>
                                        <div class="blog-one__read-more">
                                            <a href="/csr-details">Read More<span class="icon-arrow-right"></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--CSR Grid Single End--> */}
                    </div>
                </div>
            </section>

        </>
    )
}

export default CSR