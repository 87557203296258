export default class ApiUrl {
    //loacal
    // public static baseUrl: string = "http://192.168.1.10:8081/api";
    // public static fileBaseUrl: string = "http://192.168.1.10:8081/media/";
   
    //public static midUrl: string = '/api';
    // public static baseUrl: string = 'https://1wnapi.csgtech.in/api' 
    // public static fileBaseUrl: string = 'https://1wnapi.csgtech.in';

    public static midUrl: string = '/api';
    // public static baseUrl: string = 'https://api.oneworldnetwork.co/api' 
    // public static fileBaseUrl: string = 'https://api.oneworldnetwork.co/media/';

    public static baseUrl: string = 'https://oneworldnetwork.net/api' 
    public static fileBaseUrl: string = 'https://oneworldnetwork.net/media/';

    

}