
import { Validation } from "constants/Validation";
import * as Yup from "yup";

const ContactEnquirySchema =
    Yup.object().shape({
        contactEnquiryId: Yup.string(),
        name: Yup.string().required(Validation.required.message.replaceAll("^", "Name"))
            .matches(Validation.plaintext.regex, Validation.plaintext.message),
        email: Yup.string().required(Validation.required.message.replaceAll("^", "Email-Id"))
            .matches(Validation.email.regex, Validation.email.message),
        phone: Yup.string().matches(Validation.mobile.regex, Validation.mobile.message),
        category: Yup.string(),
        message: Yup.string().matches(Validation.plaintext.regex, Validation.plaintext.message),
    });


export default ContactEnquirySchema;