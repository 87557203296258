import { RouteType } from "constants/RouteType";
import { ContactEnquiryModel } from "model/screensModels/contactEnquiry/ContactEnquiryModel";
import { AuthService } from "services/auth/AuthService";

export class ContactEnquiryService {
    constructor() { }
  
    static saveContactEnquiry = async (requestData: ContactEnquiryModel) => {
      const url = RouteType.CONTACT_ENQUIRY;
      const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestData))
      return response;
    };

}