import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { ResponseModel } from 'model/utilModels/ResponseModel';
import { AuthService } from 'services/auth/AuthService';
import { msgTypes } from 'constants/msgTypes';
import showAlert from 'services/shared/alert';
import { HashRouter } from 'react-router-dom';
import { UtilService } from 'services/shared/UtilService';
import { LocalStorageService } from 'services/auth/LocalStorageService';
//design related imports for
//import 'bootstrap/dist/css/bootstrap.css';
import 'css/style.css';
import 'css/onpoint-responsive.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const toastSettings ={
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    
}

axios.interceptors.request.use(
  async (config) => {
    const token = await LocalStorageService.getToken();
    if (token) {
      // config.headers['Content-Type'] = "application/json"
      if (token != null && token!='null') {
        config.headers['Authorization'] = "Bearer " + token;
        return config;
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

//Interceptor for response body
axios.interceptors.response.use(
  async (response) => {
    let responseValue = new ResponseModel();
    try {

      const decryptRes = await AuthService.getDecryptText(JSON.parse(response.data));
      responseValue = JSON.parse(decryptRes);

      if (responseValue.statusMsg === msgTypes.SUCCESS.msg) {
        if (responseValue.displayAlert === true) {
          //showAlert(responseValue.statusMsg, responseValue.statusRemark, msgTypes.success, msgTypes.OK_KEY);
          toast.success(responseValue.statusRemark ,toastSettings);
        }
        return (response);
      }else{
        if (responseValue.displayAlert === true) {
          //showAlert(responseValue.statusMsg, responseValue.statusRemark, msgTypes.error, msgTypes.OK_KEY);
          toast.success(responseValue.statusRemark,toastSettings);
        }
      }
    } catch (error) {
      // showAlert(responseValue.statusMsg, responseValue.statusRemark, msgTypes.error, msgTypes.OK_KEY);
      toast.error(responseValue.statusRemark,toastSettings);
    }
  },
  (error) => {
    //showAlert(UtilService.upperCase(msgTypes.error), msgTypes.SOMETHING_WENT_WRONG, msgTypes.error, msgTypes.OK_KEY);
   // toast.error(msgTypes.SOMETHING_WENT_WRONG,toastSettings);
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <App />
      <ToastContainer />
    </HashRouter>
  </React.StrictMode>
);
reportWebVitals();
