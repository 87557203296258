import { routeEndpoint } from './routeEndpoints';

export const title = [
 
    //Home
    {key: routeEndpoint.HOME, value:""},

    //Dashboard
    {key: routeEndpoint.DASHBOARD, value:"Dashboard"},

    //Blog
    {key: routeEndpoint.BLOGS, value:"Blog"},

]