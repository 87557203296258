import { Link } from 'react-router-dom';
import Header from '../../sharedScreen/Header';
import Footer from 'screens/sharedScreen/Footer';
import './Verticals.scss'
const Verticals = () => {
    return (
        <div>
            {/* <!--Page Header Start--> */}
            <section className="page-header member-section">
                <div className="page-header__bg vertical-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Verticals</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Verticals</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}


            {/* // <!--vertical Start--> */}
            <section className="vertical">
                <div className="container">
                    <div className="pr-sx-about-content">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="pr-sx-about-text-area">
                                    <div className="vertical-title headline pera-content  wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                                        <span className="vertical-tag position-relative">1WN One World One Network</span>
                                        <h2>Discover <span>1WN </span>
                                            Network Verticals</h2>
                                        <p><span className="vertical-text"> 1WN</span> Network is dedicated to fostering a diverse and skilled community of logistics professionals. Within our collaborative network, freight forwarders deliver exceptional services to shippers and importers, gaining a competitive edge in the dynamic multimedia operator’s market. Our network's foundation lies in upholding high business ethics and nurturing mutual respect among our members.
                                        </p>
                                        <br />
                                        <p className="text-color-content">“At 1WN, our distinct logistics networks embody collaboration, excellence and a commitment to advancing the global freight forwarding industry.”</p>
                                    </div>
                                    <div className="vertical-list ul-li-block  wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                        <ul>
                                            <li><i class="fas fa-check-circle"></i>At 1WN, our strength lies in a robust membership base and a solid
                                                infrastructure that forms the backbone of our pro-active.</li>
                                            <li><i class="fas fa-check-circle"></i>Cooperative,
                                                and formidable network of freight forwarders.</li>
                                        </ul>
                                    </div>
                                    {/* <div className="ft-thx-btn d-flex  text-center wow flipInX" data-wow-delay="200ms" data-wow-duration="1500ms">
                                            <a className="d-flex justify-content-center align-items-center" href="#">Explore More</a>
                                        </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="pr-sx-about-exp-wrapper position-relative  wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
                                    <span className="ft-about-shape1">
                                        <img src="images/resources/vertical-shap.png" alt="" />
                                    </span>
                                    {/* <span className="ft-about-shape2">
                                            <img src="images/resources/ab-sh2.png" alt="" />
                                        </span> */}
                                    <div className="vertical-img-area">
                                        <img src="images/resources/vertical.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--vertical End--> */}

            {/* <!-- Services Section --> */}
            <section class="ft1-services-section">
                <div class="auto-container">
                    <div class="">
                        <div class="row">
                            {/* <!-- Service Block --> */}
                            <div class="col-lg-6">
                                <div class="ft1-service-block">
                                    <div class="inner-box">
                                        <div class="content">
                                            <img src="images/resources/shap-vertical.png" alt="" />
                                            <h4 className="vertical-heading">1WN Global</h4>
                                            <div class="text">Our network consists of proficient cargo specialists, experts in handling a variety of cargo types while adhering to rigorous industry standards. Our all-encompassing solutions guarantee the safe and smooth transportation of your customers’ cargo from point A to B. At 1WN Global, our mission is to cultivate a sense of community within the Freight Forwarding Industry, enabling you to broaden your business horizons through strategic global partnerships.
                                            </div>
                                        </div>
                                        <div class="side-icon">
                                            <img src="images/project/global-circle.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Service Block --> */}
                            <div class="col-lg-6">
                                <div class="ft1-service-block">
                                    <div class="inner-box">
                                        <div class="content">
                                            <img src="images/resources/shap-vertical.png" alt="" />
                                            <h4 className="vertical-heading">1WN Project </h4>
                                            <div class="text">Navigating Global Movements 1WN stands as a premier network in project logistics, facilitating global movements through Air, Sea, and Land. Our independent project logistics forwarders excel in managing intricate maneuvers, including heavy lifts and the precise handling of oversized, out-of-gauge, and weighty cargo.
                                            </div>
                                        </div>
                                        <div class="side-icon">
                                            <img src="images/project/project-circle.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Service Block --> */}
                            <div class="col-lg-6">
                                <div class="ft1-service-block">
                                    <div class="inner-box">
                                        <div class="content">
                                            <img src="images/resources/shap-vertical.png" alt="" />
                                            <h4 className="vertical-heading">1WN Express</h4>
                                            <div class="text">Excellence in Time-Critical Logistics At 1WN Express, we lead the way in delivering responsive solutions for time-critical logistics. Our network of experts guarantees round-the-clock services, including Air on Ground, onboard couriers, Next Flight Out solutions, and airport warehousing worldwide. Backed by strong airline partnerships and exclusive memberships at key airports, we ensure the reliable handling of your cargo.
                                            </div>
                                        </div>
                                        <div class="side-icon">
                                            <img src="images/project/express-circle.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Service Block --> */}
                            <div class="col-lg-6">
                                <div class="ft1-service-block">
                                    <div class="inner-box">
                                        <div class="content">
                                            <img src="images/resources/shap-vertical.png" alt="" />
                                            <h4 className="vertical-heading">1WN Dangerous Goods </h4>
                                            <div class="text">1WN Dangerous Goods unites seasoned logistics firms specializing in global hazardous cargo transportation. Our network ensures meticulous attention to detail and compliance with evolving regulations.We offer dedicated resources for air and ocean transportation, expert packing solutions compliant with industry standards, and facilitate collaborations among members engaged in significant military contracts and shipments.
                                            </div>
                                        </div>
                                        <div class="side-icon">
                                            <img src="images/project/dangours-circle.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Service Block --> */}
                            <div class="col-lg-6">
                                <div class="ft1-service-block">
                                    <div class="inner-box">
                                        <div class="content">
                                            <img src="images/resources/shap-vertical.png" alt="" />
                                            <h4 className="vertical-heading">1WN Personal </h4>
                                            <div class="text">Personal Touch Network empowers freight forwarders specializing in personal baggage and household items. Our network facilitates fully-integrated relocation services, encompassing personal and household effects removals, office, factory, and small to medium business relocations. With a curated selection of members adhering to professional standards, we foster a collaborative culture, driving collective growth and unlocking global opportunities.
                                            </div>
                                        </div>
                                        <div class="side-icon">
                                            <img src="images/project/personal-circle.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="ft1-service-block">
                                    <div class="inner-box">
                                        <div class="content">
                                            <img src="images/resources/shap-vertical.png" alt="" />
                                            <h4 className="vertical-heading">1WN Temp </h4>
                                            <div class="text">Ensuring Product Integrity 1WN excels in the domain of temperature-controlled logistics. Our global network includes experts experienced in transporting pharmaceuticals and perishable goods. Members enjoy advanced equipment and availability of cold storage facilities, guaranteeing the safe and efficient transit of products throughout the supply chain.
                                            </div>
                                        </div>
                                        <div class="side-icon">
                                            <img src="images/project/temp-circle.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div class="col-lg-6">
                                    <div class="ft1-service-block">
                                        <div class="inner-box">
                                            <div class="content">
                                                <img src="images/resources/shap-vertical.png" alt="" />
                                                <h4 className="vertical-heading">1WN Nvocc </h4>
                                                <div class="text">Ensuring Product Integrity 1WN excels in the domain of temperature-controlled logistics. Our global network includes experts experienced in transporting pharmaceuticals and perishable goods. Members enjoy advanced equipment and availability of cold storage facilities, guaranteeing the safe and efficient transit of products throughout the supply chain.
                                                </div>
                                            </div>
                                            <div class="side-icon">
                                                <img src="images/project/nvocc-circle.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Services Section --> */}

            {/* <Footer></Footer> */}
        </div >
    )
}

export default Verticals
