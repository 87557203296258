import React from 'react'

const MobileNavWrapper = () => {
    return (
        <div>
            <div className="mobile-nav__wrapper">
                <div className="mobile-nav__overlay mobile-nav__toggler"></div>
                {/* <!-- /.mobile-nav__overlay --> */}
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

                    <div className="logo-box">
                        <a href="/" aria-label="logo image"><img src="images/resources/logo-1wn.png" width="150"
                            alt="" /></a>
                    </div>
                    {/* <!-- /.logo-box --> */}
                    <div className="mobile-nav__container"></div>
                    {/* <!-- /.mobile-nav__container --> */}

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <a href="mailto:info@oneworldnetwork.co">info@oneworldnetwork.co</a>
                        </li>
                        <li>
                            <i className="fa fa-phone-alt"></i>
                            <a href="tel:+971 54 522 3903">+971 54 522 3903</a>
                        </li>
                    </ul>
                    {/* <!-- /.mobile-nav__contact --> */}
                    <div className="mobile-nav__top">
                        <div className="mobile-nav__social">
                            <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-facebook-square"></a>
                            <a href="#" className="fab fa-pinterest-p"></a>
                            <a href="#" className="fab fa-instagram"></a>
                        </div>
                        {/* <!-- /.mobile-nav__social --> */}
                    </div>
                    {/* <!-- /.mobile-nav__top --> */}



                </div>
                {/* <!-- /.mobile-nav__content --> */}
            </div>
            {/* <!-- /.mobile-nav__wrapper --> */}
        </div>
    )
}

export default MobileNavWrapper
