
import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import MobileNavWrapper from './MobileNavWrapper'
import SearchPopup from './SearchPopup'
import FixedButtons from './FixedButtons'
import Header from './Header'
import Loader from './Loader'

const LandingPage = () => {
    return (
        <div>
            <Loader />

            {/* Header Component Call   */}
            <Header></Header>

            <div className="page-wrapper">
                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>

                <Outlet></Outlet>
            </div>


            {/* Footer Component Call */}
            <Footer></Footer>

            {/* Begin:: Mobile nav wrapper */}
            <MobileNavWrapper />
            {/* End:: Mobile nav wrapper */}

            {/* Begin search popup */}
            <SearchPopup />
            {/* End:: serch popup */}

            {/* Begin:: Fix Buttons */}
            <FixedButtons />
            {/* End:: Fix buttons */}
            
        </div>
    )
}

export default LandingPage
