import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
    return (
        <>

            {/* <!--Page Header Start--> */}
            <section className="page-header member-section">
                <div className="page-header__bg terms-condition-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Terms And Conditions</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Terms And Conditions</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}

            {/* <!-- -------Content start------ --> */}
            <section class="policy-privacy">

                <div class="container">
                    <h1 class="police-heading">Who can be a 1WN member?</h1>
                    <p>We welcome all logistics, supply chain companies, but you still need to be a qualified and meet our
                        requirements(such as professional,credible......)</p>
                    <h4 class="police-heading">How many members and countries and ports?</h4>
                    <p>1WN has 5,000 members from 170+ countries, which could serve for 706 ports globally.</p>
                    {/* <!-- <ul class="pr-ul">
                        <li>
                            <span class="icon"><i class="fas fa-arrow-right"></i></span>
                            <span class="text">
                                Personalizing your user experience
                            </span>
                        </li>
                        <li>
                            <span class="icon"><i class="fas fa-arrow-right"></i></span>
                            <span class="text">
                                Enhancing website functionality.
                            </span>
                        </li>
                        <li>
                            <span class="icon"><i class="fas fa-arrow-right"></i></span>
                            <span class="text">
                                Analysing user navigation and behaviour.
                            </span>
                        </li>
                    </ul> --> */}
                    <h4 class="police-heading">What is the annual fee to join 1WN Network?</h4>
                    <p class="police-paragraph">1WN's annual fee is <span class="text-color"> USD$2,600.</span></p>
                    <h2 class="police-heading text-color-heading">Does 1WN offer free trials?(cwss)</h2>
                    <p class="police-paragraph">1WN Family does not offer free trials in order to protect our paid members.
                    </p>
                    <h4 class="police-heading">What are the main benefits provided by 1WN?</h4>
                    <p class="police-paragraph">Besides our professional network, we offer several value-added services to
                        our members. Click here to check get more benefits.</p>
                    <h4 class="police-heading">How many members do you have in certain country?</h4>
                    <p class="police-paragraph">1WN has 5,000 members from 170+ countries, you could find out their location
                        here.</p>
                    <h4 class="police-heading">How can I get more business opportunities through 1WN?</h4>
                    <p class="police-paragraph">1WN will update On-Line business everyday, members can contact each other
                        through 1WN platform. Also you can attend the annual conference to meet more new partners.</p>
                    <h4 class="police-heading">Does 1WN have exclusive membership?</h4>
                    <p class="police-paragraph">No, but 1WN has different partners in most countries.</p>
                    <h4 class="police-heading">Does the annual fee include the fee of annual conference?</h4>
                    <p class="police-paragraph">The membership fee does not include the conference fee.</p>
                    <h2 class="police-heading text-color-heading">Must I attend the annual conference?(cwss)</h2>
                    <p class="police-paragraph">No, 1WN will not force members to attend the annual conference. But to get
                        more business opportunities, most of members like to attend the annual conference in different
                        location.</p>
                    <h4 class="police-heading">How often do you hold conference ?</h4>
                    <p class="police-paragraph">One or two times per year.</p>
                    <h4 class="police-heading">Can I attend 1WN conference if I am not 1WN member?</h4>
                    <p class="police-paragraph">Sure, You could ask your 1WN member friends or partners to recommend you.
                        1WN conference aims to help all logistics companies. Non-members could find more new business
                        partners through the conference.</p>
                    <h4 class="police-heading">Does 1WN offer financial protection?</h4>
                    <p class="police-paragraph">Yes, 1WN will protect you with these situation like bankruptcy, cheating,
                        fraud. We recruit new members with strict rules and standards. Only reliable logistics companies can
                        join 1WN.</p>
                    <h4 class="police-heading">Does 1WN have limit of member in each country?</h4>
                    <p class="police-paragraph">No. 1WN is trying our best to recruit more high-quality members in the main
                        countries.</p>
                    <h4 class="police-heading">How much business can 1WN guarantee?</h4>
                    <p class="police-paragraph">To get more business, you need to be active to build the relationship with
                        other memberships. 1WN could not guarantee the amounts if you are not active.</p>
                    <h4 class="police-heading">What is difference between 1WN and other networks?</h4>
                    <p class="police-paragraph">The world's fastest growing network of global logistics companies and
                        independent freight forwarders. -Cloverleaf service</p>
                    <p class="police-paragraph">1WN has USD50,000/year financial protection; -Scure 1WN PAY;</p>
                    <h2 class="police-heading text-color-heading">1WN Insurance start by 0.015%;</h2>
                    <h5 class="police-heading">User-friendly Management System.</h5>
                    <h4 class="police-heading">Vs</h4>
                    <h5 class="police-heading">What’s the difference between your network and the other freight networks?
                    </h5>
                    <p class="police-paragraph">1WN has a payment protection program with <span class="text-color"> USD
                        5,000</span> immediate
                        dispute protection which will then increase to 20,000 depending on the years of your stay with the
                        network. We also implement a payment monitoring program where we mark members with security badges
                        according to their security status. This is to ensure that every member will be aware of the
                        members’ status before working with them. By monitoring payment habits, we are able to vastly reduce
                        and avoid transactions to delinquent forwarders or those with payment issues.</p>
                    <h4 class="police-heading">How to join 1WN network?</h4>
                    <p class="police-paragraph"><a href="#">Click HERE.</a></p>
                    <h4 class="police-heading">Does the annual 1WN membership fee include financial protection? What is the
                        coverage?</h4>
                    <p class="police-paragraph">1WN members are financially protected up to 25,000 USD per transaction
                        between network participants and $100,000 collectively across the course of the year.</p>
                    <h2 class="police-heading text-color-heading">1WN offers PR and marketing services, what can we expect
                        from 1WN?</h2>
                    <p class="police-paragraph">1WN offers multiple forms of support within PR and Marketing not only
                        through our internal Members News, Bulletins & Social media platforms but also in various media
                        outlets. This allows you to reach more inter-network members and also new external clients for your
                        business.</p>
                    <h4 class="police-heading">There are multiple vertical networks within 1WN, if I join 1 network does
                        this give me access to all
                        of 1WN Logistics Networks?</h4>
                    <p class="police-paragraph">1WN is made up of 7 Networks: Elite, Projects, Cold Chain, Critical, Asia
                        Global, Movers &
                        Consolidators. Each network is completely separate with a different group of members and their own
                        respective membership fee.</p>
                    <p class="police-paragraph">How do you qualify "Trusted and professional global freight forwarding.
                        partners" within 1WN?</p>
                    <p class="police-paragraph">Each prospective member company is required to submit strong commercial
                        references to satisfy the requirements of the management of 1WN.</p>
                    <p class="police-paragraph">It is imperative for you to prove your expertise in your local markets and
                        ensure that you have a positive reputation with other agents and carriers as well as that your
                        company has professional leadership with integrity in order to be enrolled.</p>
                    <h4 class="police-heading">Are your member's details made public and can non-members gain access to 1WN
                        member's details? <span class="text-color">Is 1WN built up of sea freight specialists or air freight
                            specialists?(cwss)</span></h4>
                    <p class="police-paragraph">1WN is built up of a number of great companies who all have their own
                        identities within their
                        specific markets. All members can undertake both modes of transport but their strong point will come
                        down to their geographical & market location.</p>
                    <h4 class="police-heading">May a company list multiple locations with 1WN Logistics Networks?</h4>
                    <p class="police-paragraph">1WN does accept multiple offices from prospective members this does come at
                        a surcharge per additional office and fall down to availability within that specific market.</p>
                    <h4 class="police-heading">Is it mandatory to attend the annual conference?</h4>
                    <p>It isn't mandatory to attend the 1WN annual conference however meeting with the members will only
                        garner better long term results</p>
                    <p>by meeting face to face. Failure to attend more than 3 consecutive conferences will leave your
                        renewal under scrutiny from the 1WN management</p>
                    <h4 class="police-heading">Do you have exclusivity?</h4>
                    <p class="police-paragraph">We are a semi-exclusive network, as we wanted to ensure a fair competition
                        in the market.</p>
                    <h4 class="police-heading">How many agents do you accept?</h4>
                    <p class="police-paragraph">1WN accepts a limited number of agents per territory depending on the
                        geographic or economic size of
                        a country. On average it would be between 5 - 10 members per country.</p>
                    <h4 class="police-heading">Do your members have a lot of inquiries to our country?</h4>
                    <p class="police-paragraph">There are hundreds of ongoing transactions between the members, for
                        confidentiality reasons we do not maintain or disclose individual transactions, we only get involved
                        if there are issues where the network requires our involvement such as payment insurance and
                        mediation. Business inquiries and transactions very much depend on how active members are within the
                        organization.</p>
                    <h4 class="police-heading">How can we touch base or communicate with the other members?</h4>
                    <p class="police-paragraph">Our members information is available and listed on our 1WN Intranet App our
                        Members only website and our Agency lists where they you have full access to their contact details.
                        We also have a WhatsApp group where we highly encourage everyone to remain active and interact
                        within the group.</p>
                    <h4 class="police-heading">How many members do you have?</h4>
                    <p class="police-paragraph">Currently we are globally represented with 321 members stations among 75
                        countries.</p>
                    <h4 class="police-heading">What are the benefits of joining your network?</h4>
                    <p class="police-paragraph">Read more about the benefits of joining 1WN here: <a href=""> click here</a>
                    </p>
                    <h4 class="police-heading">Do you have a yearly Conference? When and where?</h4>
                    <p class="police-paragraph">Yes, we conduct our partnership meetings annually in different countries
                        mostly within Asia. We highly encourage that this should be attended by our members, we believe that
                        to able to build and develop efficient partnerships, it is important for us and our partners to meet
                        one another through face2face meetings.</p>
                    <h4 class="police-heading">Do you still accept agents that are currently member in other networks?</h4>
                    <p class="police-paragraph">Yes, absolutely. Combined Logistics Networks is an inclusive community as we
                        do accept companies even if they belong to other networks.</p>
                    <h4 class="police-heading">How does your network help us grow our business?</h4>
                    <p class="police-paragraph">Our main goal is to connect you with the active and reliable freight
                        forwarders from around the world under one secured platform for you to develop business with each
                        other.</p>
                    <h4 class="police-heading">How do we get leads/inquiries from your network?</h4>
                    <p class="police-paragraph">Every member is proactively promoted within the network through our
                        marketing department. They
                        initiate various member announcements through our: mobile app, directory or agency list and many
                        other marketing efforts on social media where we feature our global community and members as well.
                        Our annual events & virtual conferences serve as excellent platforms for every member to communicate
                        and meet with each other to develop business opportunities together.</p>
                    <h4 class="police-heading">Where can I find more information about the upcoming conference in <span
                        class="text-color"> X
                        location?</span></h4>
                    <h2 class="police-heading text-color-heading">For more details about our conference in x location,
                        please visit our
                        conference website at www……..</h2>
                    <h4 class="police-heading">How can I get in touch to discuss membership options and ask questions?</h4>
                    <p class="police-paragraph">We'd be thrilled to discuss membership options and address any questions you
                        may have. Please feel free to contact us at contact <span>@a.kumar@1wn.ai</span>, and we look
                        forward to welcoming
                        you to the 1WN Family.</p>


                </div>
            </section >

        </>
    )
}

export default TermsAndConditions