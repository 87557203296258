import React from 'react'

const SocialMediaIcon = () => {
  return (
    <div>
      <div className="social-menu">
                                                <ul>
                                                    <li><a href="https://github.com/sanketbodke" target="blank"><i className="fab fa-github"></i></a></li>
                                                    <li><a href="https://www.instagram.com/imsanketbodke/" target="blank"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a href="https://www.linkedin.com/in/sanket-bodake-995b5b205/" target="blank"><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="https://codepen.io/sanketbodke"><i className="fab fa-codepen" target="blank"></i></a></li>
                                                </ul>
                                            </div>
    </div>
  )
}

export default SocialMediaIcon
