import { RouteType } from "constants/RouteType";
import { FeaturedInNewsletter } from "model/screensModels/featuredInNewsletter/FeaturedInNewsletter";
import { AuthService } from "services/auth/AuthService";

export class FeaturedInNewsletterService {
    constructor() { }
  
    static saveFeaturedInNewsletter = async (requestData: FeaturedInNewsletter) => {
      const url = RouteType.FEATURED_IN_NEWSLETTERS;
      const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestData))
      return response;
    };

}