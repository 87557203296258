import { msgTypes } from "constants/msgTypes";

export class LocalStorageService {
    constructor() { }

    //Token 
    static getToken = async () => {
        const token = ""+localStorage.getItem(msgTypes.ACCESS_TOKEN);
        return token;
    };

    static setToken = async (token: string) => {
        localStorage.setItem(msgTypes.ACCESS_TOKEN ,token);
    };
}