import { AttendeeModel } from "./AttendeeModel";

export class EventRegistrationModel{
    eventId: number;
    
    attendees:AttendeeModel[];
    companyName: string;
    companyEmail: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
    websiteUrl: string;
    memberStatus: boolean;

    addressLine1: string;
    city: number;
    state: number;
    country: number;
    zipCode: string;

    companyDetails: string;
    quantityOfParticipants: string;
    companyLogo:string;

    companyBoothType: string;
    companyHotelReservation:string;
    companySponsorship:boolean;

    termsAndCondition: boolean;

    constructor(){
        this.eventId=0;

        this.attendees = [];
        this.companyName= "";
        this.companyEmail= "";
        this.primaryPhoneNumber= "";
        this.secondaryPhoneNumber= "";
        this.websiteUrl= "";
        this.memberStatus= false;
    
        this.addressLine1= "";
        this.city= 0;
        this.state= 0;
        this.country= 0;
        this.zipCode= "";
    
        this.companyDetails= "";
        this.quantityOfParticipants= "";
        this.companyLogo="";
    
        this.companyBoothType= "";
        this.companyHotelReservation="";
        this.companySponsorship=false;
    
        this.termsAndCondition= false;
    }
}