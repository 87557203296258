export const Validation = {
    required: {
        key: 'required',
        message: '^ is required'
    },
    maxlength:{
        key: 'maxlength',
        message: 'Max. length ^ is allowed'
    },
    minlength:{
        key: 'minlength',
        message: 'Min. length ^ is required'
    },
    dateRangeValidator:{
        key: 'dateRangeValidator',
        message: 'No. of days can not be more than ^ '
    },
    whitespace: {
        key: 'whitespace',
        regex: /^\S*$/,
        message: 'Space not allowed'
    },
    alphanumericHyphenSpace: {   
        key: 'alphanumericHyphenSpace',
        regex: /^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i,
        message: 'Only (A-Z, a-z, 0-9, - and space) is allowed'
    },
    alphanumericHyphenSpaceUnderscore:{
        key: 'alphanumericHyphenSpaceUnderscore',
        regex: /^[a-z0-9]+([-_\s]{1}[a-z0-9]+)*$/i,
        message: 'Only (A-Z, a-z, 0-9, -, _ and space) is allowed'
    },
    alphanumericHyphen:{
        key: 'alphanumericHyphen',
        regex: /^[a-z0-9]+([-]{1}[a-z0-9]+)*$/i,
        message: 'Only (A-Z, a-z, 0-9, and - ) is allowed'
    },
    alphanumaric:{
        key: 'alphanumaric',
        regex: /^[a-zA-Z0-9]*$/,
        message: 'Only (A-Z, a-z, 0-9) is allowed'
    },
    numeric:{
        key: 'numeric',
        regex: /^[1-9][0-9]*$/,
        message: 'Only (0-9) numbers allowed'
    },
    zeroNumeric:{
        key: 'zeroNumeric',
        regex: /^[0-9]*$/,
        message: 'Only (0-9) numbers allowed'
    },
    decimalPlace:{
        key: 'decimalPlace',
        regex: /^[1-5]*$/,
        message: 'Only numbers(1-5) allowed'
    },
   
   
    alphanumaricSpace:{
        key: 'alphanumaricSpace',
        regex: /^[a-z0-9]+([\s]{1}[a-z0-9]+)*$/i,
        message: 'Only (A-Z, a-z, 0-9) and space is allowed'
    },
    email:{
        key: 'email',
        regex: /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,15}\b$/i,
        message: 'Enter valid Email-Id'
    },
    ifscCode:{
        key: 'ifscCode',
        regex: /^[A-Z]{4}0[A-Z0-9]{6}$/,
        message: 'Enter a valid IFSC Code'
    },
    cardNumber:{
        key: 'cardNumber',
        regex: /^[0-9]{15,16}$/,
        message: 'Enter a valid Card Number'
    },
  
    gstinNumber:{
        key: 'gstinNumber',
        regex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        message: 'Enter valid GST Number'
    },
    vatNumber:{
        key: 'vatNumber',
        regex: /^[A-Z]{2}[0-9A-Z]{5,13}$/,
        message: 'Enter valid VAT Number'
    },
    description:{
        key: 'description',
        regex: /^[a-zA-Z0-9`.+,/"-]+([-\s]{1}[a-zA-Z0-9`.+,/"-]+)*$/i,
        message: 'Only (A-Z, a-z, 0-9,`.+,/"-) and space is allowed'
    },
    password:{
        key: 'password',
        regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-])(?!.*\s)(?!.*[<>]).{8,32}$/,
        message: 'Enter a strong Password (<, > and space not allowed)'
    },
    // confirmedValidator:{
    //     regex:"",
    //     key: 'confirmedValidator',
    //     message: 'Confirm Password must be same as New Password'
    // },
    ipAddress:{
        key: 'ipAddress',
        regex: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        message: 'Enter a valid IP Address'
    },
    mobile:{
        key: 'mobile',
        regex: /^[0-9]*$/,
        message: 'Invalid Mobile Number'
    },
    pancard:{
        key: 'pancard',
        regex: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
        message: 'Enter a valid PAN'
    },
    website:{
        key: 'website',
        regex: /^[^<>]{0,}$/,
        message: 'Enter a valid Website'
    },
    plaintext:{
        key: 'plaintext',
        regex: /^[^<>]{0,}$/,
        message: 'Enter a valid text'
    },
    amount:{
        key: 'amount',
        regex: /^(0*[1-9][0-9]*([\.\][0-9]+)?|0+[\.\][0-9]*[1-9][0-9]*)$/,
        message: 'Enter a valid ^'
    },
    
    alphanumericHyphenUnderscore:{
        key: 'alphanumericHyphenUnderscore',
        regex: /^[a-zA-Z0-9_-]*$/,
        message: 'Only (A-Z, a-z, 0-9, -, _ ) is allowed'
    },
    
    ssn:{
        key: 'ssn',
        regex: /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/,
        message: 'Enter a valid SSN'
    },
    zipCode:{
        key: 'zipCode',
        regex: /^[A-Z0-9]+([-\s]{1}[A-Z0-9]+)*$/,
        message: 'Enter a valid Pin/Zip Code '
    },
   
    alphabetSpace:{
        key: 'alphabetSpace',
        regex: /^[a-z]+([\s]{1}[a-z]+)*$/i,
        message: 'Only (A-Z, a-z) and space is allowed'
    },
    alphabetCapital:{
        key: 'alphabetCapital',
        regex: /^[A-Z]+$/,
        message: 'Only (A-Z) is allowed'
    },
    isdCode:{
        key: 'isdCode',
        regex: /^[+](\d{1,3}[.\s]?)?[0-9]{1,}$/,
        message: 'Only (+, 0-9) and space is allowed'
    },
 
    
};