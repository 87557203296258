export const msgTypes = {
    APP_SECRET_KEY:
    {
        KEY: "ACC62A67A3D055E6AF68BD9D3ED9A519",
        IV: "608538a015674f17"
    },

    GOOGLE_CAPTCHA_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',

    SUCCESS: {
        code: "1000",
        msg: "SUCCESS"
    },


    DOCUMENT_TYPE: {
        JPG: "jpg",
        JPEG: "jpeg",
        PNG: "png",
        PDF: "pdf",
        TXT: "txt",
        XLSX: "xlsx",
        CSV: "csv"
    },

    SUPPORTED_IMAGE_FORMAT:["image/jpg","image/jpeg","image/png"],
    IMAGE_EXTENSIONS:["jpg","jpeg","png"],

    APPLICATION_JSON: "application/json",
    ACCESS_TOKEN: "accessToken",
    BASIC_ACCESS_TOKEN: "basicAccessToken",
    STORE_INFO: "userName",

    //status
    //SUCCESS : 'SUCCESS',

    //regex related constant
    RESPONSE_CODE_REGEX: new RegExp("^[1]{1}[0-9]{3}$"),
    RESPONSE_CODE_REGEX_THREE_DIGITS: new RegExp("^[0-1]{1}[0-9]{2}$"),

    // Login Page
    LOGIN_SUCCESS: "Login Successful",
    LOGGED_OUT: "Logout Successful",
    SOMETHING_WENT_WRONG: "Something went wrong",

    //Sweet alert 
    success: "success",
    error: "error",
    OK_KEY: 'OK',

    //Generate Form
    EMPTY_STR: '',

    //Curl strings
    HOST_URL: "HostUrl: ",
    METHOD_TYPE: "MethodType: ",
    REQUEST: "request: ",
    HEADERS_MAP: "HeadersMap: ",

    //Begin:: Table ColumnDef and Headers
    COLUMNDEF_APPROVE: 'approve',
    COLUMNDEF_REJECT: 'reject',
    HEADER_APPROVE: 'Approve',
    HEADER_REJECT: 'Reject',
    HEADER_ACTION: 'Action',

    //End:: Table ColumnDef and Headers
    
    
    VIEW: "view",
    EDIT: "edit",

    //Conformation Alert msg
    CONFORMATION_MESSAGE: {
        UPDATE_RECORD:'Update Record',
        DELETE_RECORD: 'Delete Record',
    },

    CONFORMATION_ICON:{
        WARNING: 'warning',
        ERROR: 'error'
    },


    RESPONSIVE :{
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      }


}