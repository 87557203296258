import React from 'react'

const SearchPopup = () => {
  return (
    <div>
       <div class="search-popup">
                <div class="search-popup__overlay search-toggler"></div>
                {/* <!-- /.search-popup__overlay --> */}
                <div class="search-popup__content">
                    <form action="#">
                        <label for="search" class="sr-only">search here</label>
                        {/* <!-- /.sr-only --> */}
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" class="thm-btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </form>
                </div>
                {/* <!-- /.search-popup__content --> */}
            </div>
            {/* <!-- /.search-popup --> */}
    </div>
  )
}

export default SearchPopup
