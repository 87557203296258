import { Link } from 'react-router-dom';

const FAQs = () => {
    return (
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header member-section">
                <div className="page-header__bg faqs-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>FAQs</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>FAQs</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}

            {/* <!-- Start of faq  section
	============================================= --> */}
            <section class="faqs-padding">
                <div class="container">
                    <div class="ft-faq-page-top-content d-flex justify-content-between align-items-end flex-wrap">
                        <div class="ft-section-title-2 headline pera-content">
                            <h2>Discover Frequently
                                Asked Questions from
                                <span> Our Support</span>
                            </h2>
                        </div>
                    </div>
                    <div class="ft-faq-content">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="accordion" id="accordionExample">
                                    {/* faqs 1 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Who can be a 1WN member?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                We welcome all Logistics Service Providers, Freight Forwarders,Custom Brokers,Multimodal Operators,Road & Rail transport companies,Supply Chain Companies, Warehousing and Distribution Services, Technology Partners , Freight Insurance Providers and allied industry partners  but you still need to comply with our laid out standards and requirements.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 1 */}
                                    {/* faqs 2 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                How many members and countries and ports?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                The 1WN global network will accommodate up to 500 members worldwide, with a condition that no more than 10% of the total membership shall come from any one country at any time.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 2 */}
                                    {/* faqs 3 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What is the annual fee to join 1WN?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                1WN's fee structure is as follows:
                                                <ul>
                                                    <li>USD$2000 for 1 year</li>
                                                    <li>USD$3500 for 2 years</li>
                                                    <li>USD$5000 for 3 years</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 3 */}
                                    {/* faqs 4 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                Does 1WN offer free trials?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                1WN Family does not offer free trials in order to protect our paid members.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 4 */}
                                    {/* faqs 5 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour1" aria-expanded="false" aria-controls="collapseFour">
                                                Does 1WN offer financial protection?
                                            </button>
                                        </h2>
                                        <div id="collapseFour1" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                Yes, 1WN will protect you with situations like cheating and  fraud. We recruit new members with strict rules and standards. Joining 1WN is limited to reputable logistics companies only.Please refer to our Financial Protection Plan (FPP) rules on our website, www.oneworldnetwork.co, for additional information.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 5 */}
                                    {/* faqs 6 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour6" aria-expanded="false" aria-controls="collapseFour">
                                                Does 1WN have a limit of members in each country?
                                            </button>
                                        </h2>
                                        <div id="collapseFour6" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                Yes. 1WN is trying our best to recruit more high-quality members in the main countries with a limit of not more than 10% of total membership from one country.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 7 */}
                                    {/* faqs 8 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour12" aria-expanded="false" aria-controls="collapseFour">
                                                What is the difference between 1WN and other networks?
                                            </button>
                                        </h2>
                                        <div id="collapseFour12" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                The world's most rapidly expanding network of international logistics firms and independent freight forwarders.
                                                <ul>
                                                    <li>Cloverleaf service</li>
                                                    <li>Membership limits: Restricted per country, proportional to the country's EXIM figures.</li>
                                                    <li>Supported by a board of advisors comprising industry experts and seasoned logisticians from airlines, shipping lines, and government sectors.</li>
                                                    <li>1WN offers financial protection of USD 25,000 per year.</li>
                                                    <li>Easy-to-use Management System.</li>
                                                    <li>Robust, efficient real-time communication platform for quotes and replies.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 8 */}
                                    {/* faqs 9 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour4" aria-expanded="false" aria-controls="collapseFour">
                                                How to join the 1WN?
                                            </button>
                                        </h2>
                                        <div id="collapseFour4" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                Visit us on <a href="www.oneworldnetwork.co" target='_blank'> www.oneworldnetwork.co</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 9 */}
                                    {/* faqs 10 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour5" aria-expanded="false" aria-controls="collapseFour">
                                                1WN offers PR and marketing services, what can we expect from 1WN?
                                            </button>
                                        </h2>
                                        <div id="collapseFour5" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                1WN offers multiple forms of support within PR and Marketing not only through our internal Members News, Bulletins & Social media platforms but also in various media outlets. This allows you to reach more inter-network members and also new external clients for your business.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 10 */}
                                    {/* faqs 11 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour7" aria-expanded="false" aria-controls="collapseFour">
                                                There are multiple vertical networks within 1WN, if I join 1WN network does this give me access to all of 1WN Logistics Networks?
                                            </button>
                                        </h2>
                                        <div id="collapseFour7" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                1WN is made up of 6 Networks: 1WN Global ; 1WN Express ; 1WN Project ; 1WN Temp; 1WN Personal, 1WN Dangerous Goods. Each network is completely separate with a different group of members and their own respective membership fee.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 11 */}
                                    {/* faqs 12 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour8" aria-expanded="false" aria-controls="collapseFour">
                                                How do you qualify "Trusted and professional global freight forwarding partners" within 1WN?
                                            </button>
                                        </h2>
                                        <div id="collapseFour8" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                Each prospective member company is required to submit strong commercial references to satisfy the requirements of the management of 1WN.
                                                It is imperative for you to prove your expertise in your local markets and ensure that you have a positive reputation with other agents and carriers as well as that your company has professional leadership with integrity in order to be enrolled.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 12 */}
                                    {/* faqs 13 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour9" aria-expanded="false" aria-controls="collapseFour">
                                                Are your member's details made public and can non-members gain access to 1WN member's details?
                                            </button>
                                        </h2>
                                        <div id="collapseFour9" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                1WN is made up of several outstanding industry established companies, each with a distinct identity in their respective sectors. The placement in terms of market and geography will determine their competitive advantage.Details about 1WN members are inaccessible to non-members.Information about data shall not be marked as public.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 13 */}
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="accordion" id="accordionExampl2">
                                    {/* faqs 14 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingOne2">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                                                What are the main benefits provided by 1WN?
                                            </button>
                                        </h2>
                                        <div id="collapseOne2" class="accordion-collapse collapse show" aria-labelledby="headingOne2" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                Besides our professional network, we offer several value-added services to our members. Complete details available on our website www.oneworldnetwork.co
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 14 */}
                                    {/* faqs 15 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingTwo2">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                                How can I get more business opportunities through 1WN?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                1WN will update On-Line business everyday, members can contact each other through 1WN platform. Members shall attend the annual conference to meet more business partners.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 15 */}
                                    {/* faqs 16 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingThree2">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree2" aria-expanded="false" aria-controls="collapseThree2">
                                                Does the annual fee include the fee of the annual conference?
                                            </button>
                                        </h2>
                                        <div id="collapseThree2" class="accordion-collapse collapse" aria-labelledby="headingThree2" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                The membership fee does not include the conference fee.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 16 */}
                                    {/* faqs 17 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour2">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour2" aria-expanded="false" aria-controls="collapseFour2">
                                                Must I attend the annual conference?
                                            </button>
                                        </h2>
                                        <div id="collapseFour2" class="accordion-collapse collapse" aria-labelledby="headingFour2" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                No, 1WN members won't be forced to attend the annual conference.However, 1WN expects its members to attend an annual conference every two years. Additionally, in order to take advantage of more business opportunities, the majority of members opt to travel to different locations for the annual conference.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 17 */}
                                    {/* faqs 18 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour3" aria-expanded="false" aria-controls="collapseFour2">
                                                How often do you hold conferences ?
                                            </button>
                                        </h2>
                                        <div id="collapseFour3" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                Once a year.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 18 */}
                                    {/* faqs 19 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour10" aria-expanded="false" aria-controls="collapseFour2">
                                                Can I attend the 1WN conference if I am not a 1WN member?
                                            </button>
                                        </h2>
                                        <div id="collapseFour10" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                Sure, You can ask your 1WN member friends or partners to recommend you to become a 1WN member. 1WN's conference aims to assist all logistics companies. Non-members can attend the conference as guests of a member company, though the fee varies for attendees under the 'GUEST' category.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 19 */}
                                    {/* faqs 20 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour13" aria-expanded="false" aria-controls="collapseFour2">
                                                May a company list multiple locations with 1WN Logistics Networks?
                                            </button>
                                        </h2>
                                        <div id="collapseFour13" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                1WN does accept multiple offices from prospective members; this does come at a surcharge per additional office and falls down to availability within that specific market.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 20 */}
                                    {/* faqs 21 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour14" aria-expanded="false" aria-controls="collapseFour2">
                                                How many agents do you accept?
                                            </button>
                                        </h2>
                                        <div id="collapseFour14" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                Depending on the size of a nation, either economically or geographically, 1WN only admits a certain number of agents per area. Ten percent or less of all members shall be from one nation.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 21 */}
                                    {/* faqs 22 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour15" aria-expanded="false" aria-controls="collapseFour2">
                                                How can we touch base or communicate with the other members?
                                            </button>
                                        </h2>
                                        <div id="collapseFour15" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                Our members' information is available and listed on our 1WN Intranet App (To be launched soon), our Members only website and our Agency lists where you have full access to their contact details. We also have a WhatsApp group where we highly encourage everyone to remain active and interact within the group.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 22 */}
                                    {/* faqs 23 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour16" aria-expanded="false" aria-controls="collapseFour2">
                                                Do you have a yearly Conference? When and where? .
                                            </button>
                                        </h2>
                                        <div id="collapseFour16" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                Yes, we will conduct our partnership meetings annually in different countries. We highly encourage that this should be attended by our members, we believe that to be able to build and develop efficient partnerships, it is important for us and our partners to meet one another through face2face meetings.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 23 */}
                                    {/* faqs 24 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour17" aria-expanded="false" aria-controls="collapseFour2">
                                                Do you still accept agents that are currently members in other networks?
                                            </button>
                                        </h2>
                                        <div id="collapseFour17" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                Yes, absolutely we do accept companies even if they belong to other networks.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 24 */}
                                    {/* faqs 25 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour18" aria-expanded="false" aria-controls="collapseFour2">
                                                Where can I find more information about the upcoming conference?
                                            </button>
                                        </h2>
                                        <div id="collapseFour18" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                For more details about our conference, please visit our website at <a href="oneworldnetwork.co"> www.oneworldnetwork.co</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 25 */}
                                    {/* faqs 26 */}
                                    <div class="accordion-item headline pera-content">
                                        <h2 class="accordion-header" id="headingFour3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour19" aria-expanded="false" aria-controls="collapseFour2">
                                                How can I get in touch to discuss membership options and ask questions?
                                            </button>
                                        </h2>
                                        <div id="collapseFour19" class="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampl2">
                                            <div class="accordion-body">
                                                We'd be thrilled to discuss membership options and address any questions you may have. Please feel free to contact us at info@oneworldnetwork.co, and we look forward to welcoming you to the 1WN Family.
                                            </div>
                                        </div>
                                    </div>
                                    {/* faqs 26 */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ft-faq-contact-form-wrapper">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="ft-faq-contact-form-area">
                                    <div class="ft-section-title-2 headline pera-content">
                                        <span class="sub-title">Get in Touch</span>
                                        <h2>Have Any Query</h2>
                                    </div>
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <input type="text" placeholder="Name" />
                                            </div>
                                            <div class="col-md-6">
                                                <input type="email" placeholder="Email" />
                                            </div>
                                            <div class="col-md-6">
                                                <input type="email" placeholder="Phone" />
                                            </div>
                                            <div className="col-md-6">
                                                {/* <label>Quantity of participants</label> */}
                                                <select class=" form-select-lg mb-3 form-select-home">
                                                    <option selected>Category</option>
                                                    <option value="1">Membership Related Queries</option>
                                                    <option value="2">Network Related Queries</option>
                                                    <option value="3">Payment Related Queries</option>
                                                    <option value="3">Conference Related Queries</option>
                                                    <option value="3">Other</option>
                                                </select>
                                            </div>
                                            <div class="col-md-12">
                                                <textarea name="#" placeholder="Message"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <button class="ft-submit-btn">Submit<i class="flaticon-right-arrow"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="ft-faq-form-img">
                                    <img src="images/resources/fq.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End of faq section
	============================================= --> */}

        </>
    )
}

export default FAQs