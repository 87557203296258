import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react'
import { DatePicker } from 'antd';
import MuiLabel from './MuiLabel';
import TextError from './TextError';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

export const AntdDatePicker = (props) => {
    const { label, name, ...rest } = props;
    const style = {
        width: '100%', height: "36px", border: "1px solid #c5c0c0", borderRadius: "4px"
    }
    const { setFieldValue } = useFormikContext();
    const dateChange = (values) => {
        if (values) {
            const fromdate = formatDate(values)
            setFieldValue(name, fromdate)
        } else {
            setFieldValue(name, '')
        }
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    return (
        <Field name={name} {...rest}>
            {
                ({ form, field }) => {
                    return (
                        <React.Fragment>
                            <MuiLabel {...props} />
                            <DatePicker
                                {...rest}
                                name={name}
                                onChange={dateChange}
                                style={style}
                                value={field.value !== "" ? dayjs(field.value) : null}
                                format={'DD-MM-YYYY'}
                                inputReadOnly={true}
                                showToday={false}
                            />
                            <Box className='error-box'>
                                <ErrorMessage name={name} component={TextError} />
                            </Box>
                        </React.Fragment>
                    )
                }
            }
        </Field>
    )
}