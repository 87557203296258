import React from 'react'
import Carousel from 'react-multi-carousel';

const Testimonial = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 1700, min: 1200 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2
        }
    };

    const supplyChainResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1700, min: 1200 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

  return (
    <div>
        {/* <!--Testimonial One Start--> */}
        <section className="testimonial-one">
            <div className="section-title text-center">
                        <div className="border-center section-title__tagline-box">
                            <span className="section-title__tagline">clients testimonial</span>
                        </div>
                        <h2 className="section-title__title">Member's success story<br></br> in their words</h2>
                    </div>
                <div className="container testimonial-banner-block">
                    <div className="testimonial-one__inner">
                        <div className="testimonial-one__img-one">
                            <img src="images/testimonial/testimanoil-img-1.png" alt=""></img>
                        </div>
                        <div className="row">
                            <div className="col-xl-5"></div>
                            <div className="col-xl-7 col-lg-9">
                                <div className="testimonial-one__right">
                                    <Carousel arrows={true} responsive={supplyChainResponsive} infinite={true} showDots={false} autoPlay={true}>
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Nafiz Bhuiyan</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Robert Son</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Harbert Spin</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="testimonial-one__single">
                                                    <div className="testimonial-one__quote">
                                                        <span className="icon-quote"></span>
                                                    </div>
                                                    <div className="testimonial-one__ratting">
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star"></span>
                                                        <span className="icon-star last-star"></span>
                                                    </div>
                                                    <h3 className="testimonial-one__client-name">Mainto Vula</h3>
                                                    <p className="testimonial-one__client-sub-title">Manager</p>
                                                    <p className="testimonial-one__text">Logistic service involves the planning
                                                        implementation an and control of the efficient and effective
                                                        movement and storage of goods and materials Logistic service
                                                        involves the planning implementation and control</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>

                        </div>

                        <div className="testimonial-one__dot-style">
                            <div className="swiper-dot-style1"></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Testimonial One End--> */}
    </div>
  )
}

export default Testimonial
