
import { Link } from "react-router-dom"

const NewsAndMedia = () => {
    return (
        <>

            {/* <!--Page Header Start--> */}
            <section className="page-header">
                <div className="page-header__bg news-media-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>News And Media</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>News And Media</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            {/* <!--News One Start--> */}
            <section class="blog-one">
                <div class="container">
                    <div class="section-title text-center">
                        <div class="section-title__tagline-box">
                            <span class="section-title__tagline">Latests News</span>
                        </div>
                        <h2 class="section-title__title">Streamlining your supply<br /> chain processes</h2>
                    </div>
                    <div class="row">
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="/news-and-media-details">
                                        <div class="blog-one__img">
                                            <img src="images/resources/media-1.png" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="news-and-media-details">Your trusted logistics provider
                                        Express Logistics</a></h3>
                                    <p class="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div class="blog-one__read-more">
                                        <a href="news-and-media-details">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="news-and-media-details">
                                        <div class="blog-one__img">
                                            <img src="images/resources/media-2.png" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="news-and-media-details">Logistics expertise for your the
                                        competitive advantage</a></h3>
                                    <p class="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div class="blog-one__read-more">
                                        <a href="news-and-media-details">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="news-and-media-details">
                                        <div class="blog-one__img">
                                            <img src="images/resources/media-1.png" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="news-and-media-details">Streamlining your supply chain
                                        processes Express </a></h3>
                                    <p class="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div class="blog-one__read-more">
                                        <a href="news-and-media-details">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="news-and-media-details">
                                        <div class="blog-one__img">
                                            <img src="images/resources/media-1.png" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="news-and-media-details">Streamlining your supply chain
                                        processes Express </a></h3>
                                    <p class="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div class="blog-one__read-more">
                                        <a href="news-and-media-details">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="news-and-media-details">
                                        <div class="blog-one__img">
                                            <img src="images/resources/media-1.png" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="news-and-media-details">Streamlining your supply chain
                                        processes Express </a></h3>
                                    <p class="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div class="blog-one__read-more">
                                        <a href="news-and-media-details">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                        {/* <!--News One Single Start--> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                            <div class="blog-one__single">
                                <div class="blog-one__img-box">
                                    <a href="news-and-media-details">
                                        <div class="blog-one__img">
                                            <img src="images/resources/media-2.png" alt="" />
                                        </div>
                                    </a>
                                    <div class="blog-one__date">
                                        <p>24 March</p>
                                    </div>
                                </div>
                                <div class="blog-one__content">
                                    <h3 class="blog-one__title"><a href="news-and-media-details">Streamlining your supply chain
                                        processes Express </a></h3>
                                    <p class="blog-one__text">It is a long established fact that a reader will williljl be
                                        distracted </p>
                                    <div class="blog-one__read-more">
                                        <a href="news-and-media-details">Read More<span class="icon-arrow-right"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--News One Single End--> */}
                    </div>
                </div>
            </section>
            {/* <!--News One End--> */}

        </>
    )
}
export default NewsAndMedia