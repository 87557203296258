import { Link } from "react-router-dom"

const BlogDetails = () => {
    return (
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header">
                <div className="page-header__bg blog-details-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Blog Details</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Blog Details</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            {/* <!--Blog Details Start--> */}
            <section class="blog-details">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-7">
                            <div class="blog-details__left">
                                <div class="blog-details__img">
                                    <img src="images/blog/blog-1.png" alt="" />
                                </div>
                                <div class="blog-details__content">
                                    <ul class="blog-details__meta list-unstyled">
                                        <li>
                                            <a href="#"><span class="icon-comments-2"></span>Comments (05)</a>
                                        </li>
                                        <li>
                                            <a href="#"><span class="icon-user-2"></span>By admin</a>
                                        </li>
                                    </ul>
                                    <h3 class="blog-details__title-1">Efficiently moving your goods</h3>
                                    <p class="blog-details__text-1">Construction is an essential industry that involves
                                        building and designing structures such as buildings r bridges, and more. It requires
                                        skilled workers, materials, and careful planning to ensure successful </p>
                                    <p class="blog-details__text-2">Construction is an essential industry that involves
                                        building and designing structures such as buildings bridges, and more. It requires
                                        skilled workers, materials, and careful planning to ensure successful Construction
                                        is an the a essential industry that involves building and designing structures such
                                        as buildings roa bridge</p>
                                    <div class="blog-details__client-box">
                                        <div class="blog-details__quote">
                                            <span class="icon-quote"></span>
                                        </div>
                                        <p class="blog-details__client-text">Construction is an essential industry that
                                            involves building and designing structures such as buildings r bridges, and
                                            more. It requires skilled workers, materials</p>
                                        <h4 class="blog-details__client-name">SHAkib Al Hasan</h4>
                                        <p class="blog-details__client-sub-title">Logistics Solutions</p>
                                        <div class="blog-details__client-ratting">
                                            <span class="icon-star"></span>
                                            <span class="icon-star"></span>
                                            <span class="icon-star"></span>
                                            <span class="icon-star"></span>
                                            <span class="icon-star icon-star-two"></span>
                                        </div>
                                    </div>
                                    <h3 class="blog-details__title-2">Keep your free time free!</h3>
                                    <p class="blog-details__text-3">Construction is an essential industry that involves
                                        building and designing structures such as buildings roa bridges, and more. It
                                        requires skilled workers, materials, and careful planning to ensure successful </p>
                                    <div class="blog-details__points-box">
                                        <ul class="blog-details__points-list list-unstyled">
                                            <li>
                                                <div class="icon">
                                                    <span class="icon-dabble-arrow"></span>
                                                </div>
                                                <p>Efficiently moving your goods, every time.</p>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <span class="icon-dabble-arrow"></span>
                                                </div>
                                                <p>Streamline your supply chain with our logistics</p>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <span class="icon-dabble-arrow"></span>
                                                </div>
                                                <p>Trust us for reliable and cost-effective logistics</p>
                                            </li>
                                        </ul>
                                        <ul class="blog-details__points-list list-unstyled">
                                            <li>
                                                <div class="icon">
                                                    <span class="icon-dabble-arrow"></span>
                                                </div>
                                                <p>Delivering your goods with precision</p>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <span class="icon-dabble-arrow"></span>
                                                </div>
                                                <p>Optimize your logistics with our expertise.</p>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <span class="icon-dabble-arrow"></span>
                                                </div>
                                                <p>our one-stop solution for logistics</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="blog-details__img-box">
                                        <div class="blog-details__img-box-img">
                                            <img src="images/blog/blog-details.png" alt="" />
                                        </div>
                                        <div class="blog-details__img-box-img">
                                            <img src="images/blog/blog-details-2.png" alt="" />
                                        </div>
                                        <div class="blog-details__img-box-img">
                                            <img src="images/blog/blog-details-3.png" alt="" />
                                        </div>
                                    </div>
                                    <p class="blog-details__text-4">Logistics service involves the planning, implementation,
                                        and control of the efficient and effective the a ama the transportation, storage,
                                        and distribution of goods and materials</p>
                                    {/* <div class="blog-details__tag-and-social">
                                        <div class="blog-details__tag-box">
                                            <span>Tags:</span>
                                            <a href="blog-details.html">Swift Supply Chain</a>
                                            <a href="blog-details.html">Speedy Dispatch</a>
                                        </div>
                                        <div class="blog-details__social">
                                            <a href="blog-details.html"><span class="icon-facebook-f"></span></a>
                                            <a href="blog-details.html"><span class="icon-instagram"></span></a>
                                            <a href="blog-details.html"><span class="icon-Vector"></span></a>
                                            <a href="blog-details.html"><span class="icon-linkedin-in"></span></a>
                                        </div>
                                    </div> */}
                                    {/* <div class="blog-details__pagenation-box">
                                        <ul class="list-unstyled blog-details__pagenation">
                                            <li>
                                                <div class="icon">
                                                    <a href="#"><span class="icon-arrow-left"></span></a>
                                                </div>
                                                <p>More Care<br /> places</p>
                                            </li>
                                            <li>
                                                <p>Mastering<br /> the Art</p>
                                                <div class="icon">
                                                    <a href="#"><span class="icon-arrow-right"></span></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-5">
                            <div class="sidebar">
                                <div class="sidebar__single sidebar__search">
                                    <h3 class="sidebar__title">Search</h3>
                                    <form action="#" class="sidebar__search-form">
                                        <input type="search" placeholder="Search.." />
                                        <button type="submit"><i class="fas fa-search"></i></button>
                                    </form>
                                </div>
                                <div class="sidebar__single sidebar__post">
                                    <h3 class="sidebar__title">Latest Blogs</h3>
                                    <ul class="sidebar__post-list list-unstyled">
                                        <li>
                                            <div class="sidebar__post-image">
                                                <img src="images/blog/blog-4.png" alt="" />
                                            </div>
                                            <div class="sidebar__post-content">
                                                <p class="sidebar__post-date"><i class="icon-calender"></i>Jan 10,2022</p>
                                                <h3>
                                                    <a href="/blog-details">Optimize your logistics with our
                                                        expertise</a>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar__post-image">
                                                <img src="images/blog/blog-6.png" alt="" />
                                            </div>
                                            <div class="sidebar__post-content">
                                                <p class="sidebar__post-date"><i class="icon-calender"></i>Jan 10,2022</p>
                                                <h3>
                                                    <a href="/blog-details">We make logistics easy for your business</a>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar__post-image">
                                                <img src="images/blog/blog-5.png" alt="" />
                                            </div>
                                            <div class="sidebar__post-content">
                                                <p class="sidebar__post-date"><i class="icon-calender"></i>Jan 10,2022</p>
                                                <h3>
                                                    <a href="/blog-details">Your one-stop solution for services</a>
                                                </h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar__single sidebar__category">
                                    <h3 class="sidebar__title">Category</h3>
                                    <ul class="sidebar__category-list list-unstyled">
                                        <li>
                                            <div class="icon">
                                                <span class="icon-dabble-arrow"></span>
                                            </div>
                                            <a href="#">Express Freight Solutions<span>(02)</span></a>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-dabble-arrow"></span>
                                            </div>
                                            <a href="#">QuickMove Logistics<span>(02)</span></a>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-dabble-arrow"></span>
                                            </div>
                                            <a href="#">Speedy Dispatch<span>(02)</span></a>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-dabble-arrow"></span>
                                            </div>
                                            <a href="#">Swift Supply Chain<span>(02)</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar__single sidebar__tags">
                                    <h3 class="sidebar__title">Tags</h3>
                                    <div class="sidebar__tags-list">
                                        <a href="#">Events</a>
                                        <a href="#">Conferences</a>
                                        <a href="#">News & Media</a>
                                        <a href="#">Verticals</a>
                                        <a href="#">CSR</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Blog Details End--> */}
        </>
    )
}

export default BlogDetails