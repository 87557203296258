import { msgTypes } from "constants/msgTypes";
import { RouteType } from "constants/RouteType";
import { AuthService } from "services/auth/AuthService";



export class UtilService {
    constructor() { }
    
    static sentenceCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() +
                    txt.substr(1).toLowerCase();
            });
    }

    static upperCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.toUpperCase();
            });
    }

    static lowerCase = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/\w\S*/g,
            function (txt) {
                return txt.toLowerCase();
            });
    }

    //End:: change Case

    //load country
        static getCountryList = async () => {
            const url = RouteType.GET_COUNTRY;
            const response = AuthService.postCryptoRequest(url, msgTypes.EMPTY_STR);
            return response;
        };

     //load State List
     static getStateList = async (countryId:string) => {
        const url = RouteType.GET_STATE;
        const response = AuthService.postCryptoRequest(url, JSON.stringify(countryId));
        return response;
    };

    //load State List
    static getCityList = async (stateId:string) => {
        const url = RouteType.GET_CITY;
        const response = AuthService.postCryptoRequest(url, JSON.stringify(stateId));
        return response;
    };
}