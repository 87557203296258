import { Link } from "react-router-dom";


const Blog = () => {
    return (
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header">
                <div className="page-header__bg blog-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Blog</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Blog</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            {/* <!--Blog Page Start--> */}
            <section class="blog-page">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-7">
                            {/* <!--Blog Page Single Start--> */}
                            <div class="blog-page__single">
                                <a href="/blog-details">
                                    <div class="blog-page__img">
                                        <img src="images/blog/blog-1.png" alt="" />
                                        <div class="blog-page__date">
                                            <p>10 Jan 2024</p>
                                        </div>
                                    </div>
                                </a>
                                <div class="blog-page__content">
                                    <ul class="blog-page__meta list-unstyled">
                                        <li>
                                            <a href="#"><span class="icon-comments-2"></span>Comments (05)</a>
                                        </li>
                                        <li>
                                            <a href="#"><span class="icon-user-2"></span>By admin</a>
                                        </li>
                                    </ul>
                                    <h3 class="blog-page__title"><a href="/blog-details">Creating Lasting
                                        Impressions</a></h3>
                                    <p class="blog-page__text">Construction is an essential industry that involves building
                                        and designing structures such as buildings roads, bridges, and more. It requires
                                        skilled workers, materials, and careful</p>
                                    <div class="blog-page__btn-box">
                                        <a href="/blog-details" class="thm-btn blog-page__btn">Read
                                            more<span></span></a>
                                    </div>
                                    {/* <div class="blog-page__social">
                                        <a href="blog-details.html"><span class="icon-instagram"></span></a>
                                        <a href="blog-details.html"><span class="icon-facebook-f"></span></a>
                                        <a href="blog-details.html"><span class="icon-Vector"></span></a>
                                        <a href="blog-details.html"><span class="icon-linkedin-in"></span></a>
                                    </div> */}
                                </div>
                            </div>
                            {/* <!--Blog Page Single End-->
                            <!--Blog Page Single Start--> */}
                            <div class="blog-page__single">
                                <a href="/blog-details">
                                    <div class="blog-page__img">
                                        <img src="images/blog/blog-2.png" alt="" />
                                        <div class="blog-page__date">
                                            <p>12 March 2024</p>
                                        </div>
                                    </div>
                                </a>
                                <div class="blog-page__content">
                                    <ul class="blog-page__meta list-unstyled">
                                        <li>
                                            <a href="#"><span class="icon-comments-2"></span>Comments (05)</a>
                                        </li>
                                        <li>
                                            <a href="#"><span class="icon-user-2"></span>By admin</a>
                                        </li>
                                    </ul>
                                    <h3 class="blog-page__title"><a href="/Blog-details">Efficiently moving your
                                        goods</a></h3>
                                    <p class="blog-page__text">Construction is an essential industry that involves building
                                        and designing structures such as buildings roads, bridges, and more. It requires
                                        skilled workers, materials, and careful</p>
                                    <div class="blog-page__btn-box">
                                        <a href="/blog-details" class="thm-btn blog-page__btn">Read
                                            more<span></span></a>
                                    </div>
                                    {/* <div class="blog-page__social">
                                        <a href="blog-details.html"><span class="icon-instagram"></span></a>
                                        <a href="blog-details.html"><span class="icon-facebook-f"></span></a>
                                        <a href="blog-details.html"><span class="icon-Vector"></span></a>
                                        <a href="blog-details.html"><span class="icon-linkedin-in"></span></a>
                                    </div> */}
                                </div>
                            </div>
                            {/* <!--Blog Page Single End-->
                            <!--Blog Page Single Start--> */}
                            <div class="blog-page__single">
                                <a href="/blog-details">
                                    <div class="blog-page__img">
                                        <img src="images/blog/blog-3.png" alt="" />
                                        <div class="blog-page__date">
                                            <p>20 July 2024</p>
                                        </div>
                                    </div>
                                </a>
                                <div class="blog-page__content">
                                    <ul class="blog-page__meta list-unstyled">
                                        <li>
                                            <a href="#"><span class="icon-comments-2"></span>Comments (05)</a>
                                        </li>
                                        <li>
                                            <a href="#"><span class="icon-user-2"></span>By admin</a>
                                        </li>
                                    </ul>
                                    <h3 class="blog-page__title"><a href="/blog-details">Cultivate Success with
                                        Agriculture</a></h3>
                                    <p class="blog-page__text">Construction is an essential industry that involves building
                                        and designing structures such as buildings roads, bridges, and more. It requires
                                        skilled workers, materials, and careful</p>
                                    <div class="blog-page__btn-box">
                                        <a href="/blog-details" class="thm-btn blog-page__btn">Read
                                            more<span></span></a>
                                    </div>
                                    {/* <div class="blog-page__social">
                                        <a href="blog-details.html"><span class="icon-instagram"></span></a>
                                        <a href="blog-details.html"><span class="icon-facebook-f"></span></a>
                                        <a href="blog-details.html"><span class="icon-Vector"></span></a>
                                        <a href="blog-details.html"><span class="icon-linkedin-in"></span></a>
                                    </div> */}
                                </div>
                            </div>
                            {/* <!--Blog Page Single End--> */}
                            {/* <div class="blog-page__pagination">
                                <ul class="pg-pagination list-unstyled">
                                    <li class="count"><a href="#">1</a></li>
                                    <li class="count"><a href="#">2</a></li>
                                    <li class="count"><a href="#">3</a></li>
                                    <li class="next">
                                        <a href="#" aria-label="Next"><i class="icon-dabble-arrow"></i></a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        <div class="col-xl-4 col-lg-5">
                            <div class="sidebar">
                                <div class="sidebar__single sidebar__search">
                                    <h3 class="sidebar__title">Search</h3>
                                    <form action="#" class="sidebar__search-form">
                                        <input type="search" placeholder="Search.." />
                                        <button type="submit"><i class="fas fa-search"></i></button>
                                    </form>
                                </div>
                                <div class="sidebar__single sidebar__post">
                                    <h3 class="sidebar__title">Latest Blogs</h3>
                                    <ul class="sidebar__post-list list-unstyled">
                                        <li>
                                            <div class="sidebar__post-image">
                                                <img src="images/blog/blog-4.png" alt="" />
                                            </div>
                                            <div class="sidebar__post-content">
                                                <p class="sidebar__post-date"><i class="icon-calender"></i>Jan 10,2022</p>
                                                <h3>
                                                    <a href="/blog-details">Optimize your logistics with our
                                                        expertise</a>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar__post-image">
                                                <img src="images/blog/blog-6.png" alt="" />
                                            </div>
                                            <div class="sidebar__post-content">
                                                <p class="sidebar__post-date"><i class="icon-calender"></i>Jan 10,2022</p>
                                                <h3>
                                                    <a href="/blog-details">We make logistics easy for your business</a>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar__post-image">
                                                <img src="images/blog/blog-5.png" alt="" />
                                            </div>
                                            <div class="sidebar__post-content">
                                                <p class="sidebar__post-date"><i class="icon-calender"></i>Jan 10,2022</p>
                                                <h3>
                                                    <a href="/blog-details">Your one-stop solution for services</a>
                                                </h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar__single sidebar__category">
                                    <h3 class="sidebar__title">Category</h3>
                                    <ul class="sidebar__category-list list-unstyled">
                                        <li>
                                            <div class="icon">
                                                <span class="icon-dabble-arrow"></span>
                                            </div>
                                            <a href="#">Express Freight Solutions<span>(02)</span></a>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-dabble-arrow"></span>
                                            </div>
                                            <a href="#">QuickMove Logistics<span>(02)</span></a>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-dabble-arrow"></span>
                                            </div>
                                            <a href="#">Speedy Dispatch<span>(02)</span></a>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-dabble-arrow"></span>
                                            </div>
                                            <a href="#">Swift Supply Chain<span>(02)</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar__single sidebar__tags">
                                    <h3 class="sidebar__title">Tags</h3>
                                    <div class="sidebar__tags-list">
                                        <a href="#">Events</a>
                                        <a href="#">Conferences</a>
                                        <a href="#">News & Media</a>
                                        <a href="#">Verticals</a>
                                        <a href="#">CSR</a>
                                        {/* <a href="#">Reliable Third</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Blog Page End--> */}
        </>
    )
}

export default Blog