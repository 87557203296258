import { Validation } from "constants/Validation";
import * as Yup from "yup";

const ContactPersonSchema = 
Yup.object().shape({
        name: Yup.string().required(Validation.required.message.replaceAll("^","Name"))
                            .matches(Validation.plaintext.regex , Validation.plaintext.message),
        position: Yup.string().required(Validation.required.message.replaceAll("^","Designation"))
                            .matches(Validation.plaintext.regex , Validation.plaintext.message),
        phoneNumber: Yup.string().required(Validation.required.message.replaceAll("^","Phone Number"))
                            .matches(Validation.mobile.regex , Validation.mobile.message)
                            .min(10,Validation.minlength.message.replaceAll("^",10))
                            .max(15,Validation.maxlength.message.replaceAll("^",15)),
        email: Yup.string().required(Validation.required.message.replaceAll("^","Email-Id"))
                            .matches(Validation.email.regex , Validation.email.message),
        contactPersonImage: Yup.string(),
        contactIsrepresentative: Yup.boolean(),
 })


export default ContactPersonSchema;