import { Link } from "react-router-dom"

const Conferences = () => {
    return (
        <>
            {/* <!--Page Header Start--> */}
            <section className="page-header">
                <div className="page-header__bg conference-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Conferences</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Conferences</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Page Header End--> */}

            {/* <!--Project One Start--> */}
            <section class="project-one pb-50">
                <div class="container">
                    <div class="section-title text-center">
                        <div class="section-title__tagline-box">
                            <span class="section-title__tagline">Our Conferences</span>
                        </div>
                        <h2 class="section-title__title">Let’s discover all our <br />recent project</h2>
                    </div>
                    <div class="row masonary-layout">
                        {/* <!--Project One Single Start--> */}
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <Link to="#">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/conferences-1.webp" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title">
                                                <Link to="#">Event 1</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <Link to="">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/conferences-2.webp" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title">
                                                <Link to="#">Event 2</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <Link to="">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/conferences-3.webp" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title">
                                                <Link to="#">Event 3</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <Link to="#">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/conferences-4.webp" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title">
                                                <Link to="#">Event 4</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <Link to="">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/conferences-5.webp" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title">
                                                <Link to="project-details.html">Event 5</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <Link to="#">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/conferences-7.webp" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title">
                                                <Link to="#">Event 6</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Project One Single End--> */}
                        {/* <!--Project One Single Start--> */}
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <Link to="#">
                                <div class="project-one__single">
                                    <div class="project-one__img-box">
                                        <div class="project-one__img">
                                            <img src="images/resources/conferences-6.webp" alt="" />
                                        </div>
                                        <div class="project-one__content">
                                            <p class="project-one__sub-title">Delhi</p>
                                            <h3 class="project-one__title"><Link to="#">Event 7</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <!--Project One Single End--> */}
                    </div>
                </div>
            </section>
            {/* <!--Project One End--> */}
        </>
    )
}
export default Conferences