
import { Validation } from "constants/Validation";
import * as Yup from "yup";

const AttendeeSchema = 
    Yup.object().shape({
        gender: Yup.string().required(Validation.required.message.replaceAll("^","Gender")),
        name: Yup.string().required(Validation.required.message.replaceAll("^","Name"))
                          .matches(Validation.plaintext.regex , Validation.plaintext.message),
        position: Yup.string().required(Validation.required.message.replaceAll("^","Designation"))
                          .matches(Validation.plaintext.regex , Validation.plaintext.message),
        phoneNumber: Yup.string().required(Validation.required.message.replaceAll("^","Contact Number"))
                          .matches(Validation.mobile.regex , Validation.mobile.message)
                          .min(10,Validation.minlength.message.replaceAll("^",10))
                          .max(15,Validation.maxlength.message.replaceAll("^",15)),
        emailId: Yup.string().required(Validation.required.message.replaceAll("^","Email-Id"))
                          .matches(Validation.email.regex , Validation.email.message),
        formalPhoto: Yup.string().required(Validation.required.message.replaceAll("^","Photo")),
        foodType: Yup.string().required(Validation.required.message.replaceAll("^","Food Type")),
        sponsorshipNeeded: Yup.boolean()
      });

      


export default AttendeeSchema;