import axios from "axios";
import { RouteType } from "constants/RouteType";
import { msgTypes } from "constants/msgTypes";
import { AuthModel } from "model/utilModels/AuthModel";
import { ResponseModel } from "model/utilModels/ResponseModel";
import ApiUrl from "services/shared/ApiUrl";
var CryptoJS = require("crypto-js");

export class AuthService {
  constructor() { }

  // static autanticateUser = async (authData: AuthModel) => {
  //   const url = RouteType.ADMIN + RouteType.AUTH + RouteType.LOGIN;
  //   const response = await AuthService.postCryptoRequest(url, JSON.stringify(authData))
  //   return response;
  // };

  // //To get menu list
  // static getMenuList = async () => {
  //   const url = RouteType.ADMIN + RouteType.MENU;
  //   const response = await AuthService.postCryptoRequest(url, msgTypes.EMPTY_STR)
  //   return response;
  // };

  //Encryption
  static getEncryptText = async (plaintText: string) => {
    let _key = CryptoJS.enc.Utf8.parse(msgTypes.APP_SECRET_KEY.KEY);
    let _iv = CryptoJS.enc.Utf8.parse(msgTypes.APP_SECRET_KEY.IV);
    let encrypted = CryptoJS.AES.encrypt(
      plaintText, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  //Decryption
  static getDecryptText = async (encrypted: string) => {
    let _key = CryptoJS.enc.Utf8.parse(msgTypes.APP_SECRET_KEY.KEY);
    let _iv = CryptoJS.enc.Utf8.parse(msgTypes.APP_SECRET_KEY.IV);

    let decrypted = CryptoJS.AES.decrypt(
      encrypted, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return decrypted
  }


  //For Encryprtion and Decryption
  static postCryptoRequest = async (apiUrl: string, requestJsonStr: string | FormData) => {
   
    // apiUrl = ApiUrl.baseUrl + apiUrl;
    apiUrl = ApiUrl.baseUrl + apiUrl + "/";
    
    //prepare encrypted string request body
    let requestObj = null;
    if(!(requestJsonStr instanceof FormData)){
      if(requestJsonStr.length>0){
        const encrypteddata = await AuthService.getEncryptText(requestJsonStr)
        requestObj = {
          data: encrypteddata
        }
      }
    }else{
      requestObj = requestJsonStr;
    }
    const encryptRes = await axios.post<string>(apiUrl, requestObj, { responseType: 'text' as 'json' })
    const decryptRes = await AuthService.getDecryptText(JSON.parse(encryptRes.data));

    let response = new ResponseModel();
    response = JSON.parse(decryptRes);

    return response;
  }




}