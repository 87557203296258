
export const routeEndpoint = {

    //home
    HOME: 'home',

    //Dashboard
    DASHBOARD: 'dashboard',
    BLOGS: 'blogs'

   
}