import { Validation } from "constants/Validation";
import * as Yup from "yup";

const NewsletterSubscribersSchema =
    Yup.object().shape({
        newsletterSubscriberId: Yup.string(),
        emailId: Yup.string()
            .required(Validation.required.message.replaceAll("^", "Email"))
            .matches(Validation.email.regex, Validation.email.message)
    });




export default NewsletterSubscribersSchema;