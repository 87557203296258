export class ContactEnquiryModel {
    contactEnquiryId: string;
    name: string;
    email: string;
    phone: string;
    category: string;
    message: string;

    constructor() {
        this.contactEnquiryId=""
        this.name=""
        this.email=""
        this.phone=""
        this.category=""
        this.message=""

    }
}