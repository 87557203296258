import React from 'react'

const Loader = () => {
  return (
    <div>
       <div class="preloader">
        </div>
    </div>
  )
}

export default Loader
