
import { Validation } from "constants/Validation";
import * as Yup from "yup";

const EventRegistrationSchema = [
    Yup.object().shape({
      eventId: Yup.string().required(Validation.required.message.replaceAll("^","Event")),
    }),
    
      Yup.object().shape({
        attendees: Yup.array()
        }),

      Yup.object().shape({
        companyName: Yup.string().required(Validation.required.message.replaceAll("^","Company Name"))
                          .matches(Validation.plaintext.regex , Validation.plaintext.message),
        companyEmail: Yup.string().required(Validation.required.message.replaceAll("^","Company Email"))
                          .matches(Validation.email.regex , Validation.email.message),
        primaryPhoneNumber: Yup.string().required(Validation.required.message.replaceAll("^","Phone Number"))
                          .matches(Validation.mobile.regex , Validation.mobile.message)
                          .min(10,Validation.minlength.message.replaceAll("^",10))
                          .max(15,Validation.maxlength.message.replaceAll("^",15)),
        secondaryPhoneNumber: Yup.string()
                          .matches(Validation.mobile.regex , Validation.mobile.message)
                          .min(10,Validation.minlength.message.replaceAll("^",10))
                          .max(15,Validation.maxlength.message.replaceAll("^",15)),
        websiteUrl: Yup.string().matches(Validation.website.regex , Validation.website.message),
        companyDetails: Yup.string().required(Validation.required.message.replaceAll("^","Company Details"))
                          .matches(Validation.plaintext.regex , Validation.plaintext.message),
        companyLogo: Yup.string().required(Validation.required.message.replaceAll("^","Company Logo")),
        memberStatus: Yup.boolean(),
        
      }),

      Yup.object().shape({
        addressLine1: Yup.string().required(Validation.required.message.replaceAll("^","Address"))
                          .matches(Validation.plaintext.regex , Validation.plaintext.message),
        city: Yup.string().required(Validation.required.message.replaceAll("^","City")),
        state: Yup.string().required(Validation.required.message.replaceAll("^","State")),
        country: Yup.string().required(Validation.required.message.replaceAll("^","Country")),
        zipCode: Yup.string().required(Validation.required.message.replaceAll("^","Zipcode"))
                          .matches(Validation.zipCode.regex , Validation.zipCode.message),
      }),
      
      Yup.object().shape({
        companyHotelReservation: Yup.string().required(Validation.required.message.replaceAll("^","Hotel Reservation")),
        companyBoothType: Yup.string().required(Validation.required.message.replaceAll("^","Booth Type")),
        quantityOfParticipants: Yup.string().required(Validation.required.message.replaceAll("^","Quantity of Participants")),
      }),
];

export default EventRegistrationSchema;