export class FeaturedInNewsletter {
    featuredInNewsletterId: string;
    name: string;
    category: string;
    message: string;
    email: string;

    constructor() {
        this.featuredInNewsletterId="";
        this.name="";
        this.category="";
        this.message="";
        this.email="";
    }
}