import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import RECAPTCHA from "react-google-recaptcha";
import Carousel from 'react-multi-carousel';
import FormikControl from 'components/wrapperComponents/FormikControl'; // Adjust the path based on your project structure
import {
    Box,
    Grid,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Button,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { MembershipFormModel } from 'model/screensModels/membershipRegistration/MembershipFormModel';
import MembershipFormSchema from "schemas/membershipForm/MembershipFormSchema";
import { MembershipService } from 'services/menu/membership/MembershipService';
import { msgTypes } from 'constants/msgTypes';
import { UtilService } from 'services/shared/UtilService';
import MembershipContactPerson from './MembershipContactPerson';
import { UploadFileService } from 'services/shared/UploadFileService';



const MembershipForm = () => {
    const [captchaMatched, setCaptchaMatched] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [initialValues, setInitialValues] = useState(new MembershipFormModel());
    const [memberlist, setMemberList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const recaptcha = useRef();


    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 1700, min: 1200 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2
        }
    };

    const validation = MembershipFormSchema

    useEffect(() => {
        loadCountry();
    }, [])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //load country
    const loadCountry = async () => {
        const res = await UtilService.getCountryList();
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setCountryList(res.data);
        }
    }

    //load state list
    const loadStateList = async (countryId) => {
        const res = await UtilService.getStateList(countryId);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setStateList(res.data);
        }
    }

    //load City list
    const loadCityList = async (stateId) => {
        const res = await UtilService.getCityList(stateId);
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setCityList(res.data);
        }
    }


    const onCaptchaSubmit = (value) => {
        if (value)
            setCaptchaMatched(true)
        else
            setCaptchaMatched(false)
    }

    const prepareRequestBody = async (values) => {
        const prepareData = new MembershipFormModel();

        memberlist.map(data => {
            prepareData.contactPerson.push(data);
        })
        //  prepareData.contactPerson = memberlist ? values.contactPerson: [];
        prepareData.companyName = values.companyName ? values.companyName : "";
        prepareData.companyEmail = values.companyEmail ? values.companyEmail : "";
        prepareData.primaryPhoneNumber = values.primaryPhoneNumber ? values.primaryPhoneNumber : "";
        prepareData.secondaryPhoneNumber = values.secondaryPhoneNumber ? values.secondaryPhoneNumber : "";
        prepareData.websiteUrl = values.websiteUrl ? values.websiteUrl : "";
        prepareData.accountingEmail = values.accountingEmail ? values.accountingEmail : "";
        prepareData.addressLine1 = values.addressLine1 ? values.addressLine1 : "";
        prepareData.city = values.city ? values.city : 0;
        prepareData.state = values.state ? values.state : 0;
        prepareData.country = values.country ? values.country : 0;
        prepareData.zipCode = values.zipCode ? values.zipCode : "";
        prepareData.companyDetails = values.companyDetails ? values.companyDetails : "";
        prepareData.instagramUrl = values.instagramUrl ? values.instagramUrl : "";
        prepareData.linkedinUrl = values.linkedinUrl ? values.linkedinUrl : "";
        prepareData.twitterUrl = values.twitterUrl ? values.twitterUrl : "";
        prepareData.facebookUrl = values.facebookUrl ? values.facebookUrl : "";
        prepareData.youtubeUrl = values.youtubeUrl ? values.youtubeUrl : "";
        prepareData.membershipType = values.membershipType ? values.membershipType : "";

        //to get image url
        if (values.companyLogo) {
            const formData = new FormData();
            formData.append("file", values.companyLogo);
            const imageurlData = await UploadFileService.getImagePath(formData);
            prepareData.companyLogo = imageurlData.data.fileName;
        } else {
            prepareData.companyLogo = '';
        }

        return prepareData
    }

    const submitMembership = async (values) => {
        const requestBody = await prepareRequestBody(values);
        const res = await MembershipService.addMembership(requestBody)
        if (res.statusMsg === msgTypes.SUCCESS.msg) {
            setActiveStep(0);
            setMemberList([]);
            return true
        }
        return false;
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <section className="page-header member-section">
                <div className="page-header__bg membersship-banner">
                </div>
                <div className="container membership-container">
                    <div className="page-header__inner">
                        <h2>Membership Registration</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Membership Registration</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="brand-one">
                <div className="container">
                    {/* <div className="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 100,
                "slidesPerView": 5,
                "loop": true,
                "navigation": {
                    "nextEl": "#brand-one__swiper-button-next",
                    "prevEl": "#brand-one__swiper-button-prev"
                },
                "autoplay": { "delay": 5000 },
                "breakpoints": {
                    "0": {
                        "spaceBetween": 30,
                        "slidesPerView": 1
                    },
                    "375": {
                        "spaceBetween": 30,
                        "slidesPerView": 1
                    },
                    "575": {
                        "spaceBetween": 30,
                        "slidesPerView": 2
                    },
                    "767": {
                        "spaceBetween": 50,
                        "slidesPerView": 3
                    },
                    "991": {
                        "spaceBetween": 50,
                        "slidesPerView": 4
                    },
                    "1199": {
                        "spaceBetween": 100,
                        "slidesPerView": 5
                    },
                    "1199": {
                        "spaceBetween": 100,
                        "slidesPerView": 5
                    }
                }}'>
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-1.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-2.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-3.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-4.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-5.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-1.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-2.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-3.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-4.png" alt=""></img>
                                </div>
                                <div className="swiper-slide">
                                    <img src="images/brand/brand-1-5.png" alt=""></img>
                                </div>
                            </div>
                        </div> */}
                    <h2
                        className
                        =
                        "brand-one__text count-box section-title__title"
                    > 1WN &nbsp;Welcomes you to our growing<br></br> global network in <span
                        className
                        =
                        "count-text"
                        data-stop
                        =
                        "40"
                        data-speed
                        =
                        "1500"
                    >00</span><span>+</span> Countries</h2>
                    <Carousel arrows={true} responsive={responsive} showDots={false} autoPlay={true} transitionDuration={200} infinite={true}>

                        <div className="swiper-slide">
                            <img src="images/flags/flag1.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag2.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag3.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag4.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag5.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag6.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag1.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag2.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag3.jpg" alt=""></img>
                        </div>
                        <div className="swiper-slide">
                            <img src="images/flags/flag4.jpg" alt=""></img>
                        </div>
                    </Carousel>
                </div>
            </section>

            <Box className="membership-wrapper" id="membership" width="100%" height="100%" margin="0 auto" padding="0px">
                <Typography variant="h4" align="center" marginBottom="20px">
                    Membership Form
                </Typography>
                <Stepper
                    activeStep={activeStep}
                    orientation={isSmallScreen ? 'vertical' : 'horizontal'}
                    sx={{
                        width: '80%',
                        backgroundColor: 'transparent',
                        marginBottom: '13px',
                        fontWeight: '300',
                        margin: '5 auto',
                        fontSize: '1.5rem' // Adjust the font size for larger labels
                    }}
                >
                    {[
                        'Company Details',
                        'Company Address',
                        'Company Description',
                        'Company Social Profiles',
                        'Membership Type',
                        'Contact Person',
                    ].map((label, index) => (
                        <Step key={index}>
                            <StepLabel
                                sx={{
                                    '& .MuiStepLabel-label': {
                                        fontSize: '1.0rem', // Adjust the font size for larger labels
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                    }
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box className="main-box" width="80%" margin="0 auto" padding="20px" marginBottom='60px'>
                    <Grid container justifyContent="left">
                        <Paper
                            className="event-paper"
                            style={{
                                // padding: '20px',
                                marginBottom: '20px',
                                backgroundColor: '#ffffff'
                            }}
                        >
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validation[activeStep]}
                                onSubmit={async (values, { resetForm }) => {
                                    if (activeStep < validation.length - 1) {
                                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                    }
                                    const res = await submitMembership(values);
                                    if (res)
                                        resetForm();
                                }}
                            >
                                {({ handleSubmit, isSubmitting, isValid, dirty, values, formik, setFieldValue, setFieldTouched }) => (
                                    <Form onSubmit={handleSubmit}>

                                        {activeStep === 0 && (
                                            <>
                                            <div className='member-block-body'>
                                                <Typography variant="h4" align="center" marginBottom="30px">
                                                    Company Details
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="companyName"
                                                            label="Company Name"
                                                            placeholder="Enter Your Company Name"
                                                            requiredField
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="companyEmail"
                                                            label="Company Email"
                                                            placeholder="Enter Your Company Email"
                                                            requiredField
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="primaryPhoneNumber"
                                                            label="Mobile Number"
                                                            placeholder="Enter Your Mobile Number"
                                                            requiredField
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="secondaryPhoneNumber"
                                                            label="Alternative Mobile Number"
                                                            placeholder="Enter Your Alternative Mobile Number"

                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="websiteUrl"
                                                            label="URL"
                                                            placeholder="Enter Your URL"

                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="accountingEmail"
                                                            label="Accounting Email"
                                                            placeholder="Enter Your Accounting Email"

                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="uploadImage"
                                                            name="companyLogo"
                                                            label="Company Logo"
                                                        // placeholder="Enter Your Email Id"
                                                        // requiredField
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Box marginTop="20px" className="next-btn" display="flex" justifyContent="space-between">
                                                    <Button
                                                        //type="submit"
                                                        className='btn btn-submit'
                                                        onClick={handleNext}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={!dirty || !(isValid)}
                                                    >
                                                        Next
                                                    </Button>
                                                </Box>
                                                </div>
                                            </>
                                        )}
                                        {activeStep === 1 && (
                                            <>
                                            <div className='member-block-body'>
                                                <Typography variant="h4" align="center" marginBottom="30px">
                                                    Company Address
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="addressLine1"
                                                            label="Address"
                                                            placeholder="Enter Your Address"
                                                            requiredField
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl control='autocomplete'
                                                            label="Country"
                                                            name="country"
                                                            placeholder="Select Country"
                                                            requiredField={true}
                                                            options={countryList}
                                                            onChange={(e) => { loadStateList({ "country": e.countryIdentifier }) }}
                                                            keyfield="name"
                                                            valuefield="countryIdentifier"
                                                        />


                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl control='autocomplete'
                                                            label="State"
                                                            name="state"
                                                            placeholder="Select State"
                                                            requiredField={true}
                                                            options={stateList}
                                                            onChange={(e) => { loadCityList({ "state": e.stateIdentifier }) }}
                                                            keyfield="name"
                                                            valuefield="stateIdentifier"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl control='autocomplete'
                                                            label="City"
                                                            name="city"
                                                            placeholder="Select City"
                                                            requiredField={true}
                                                            options={cityList}
                                                            onChange={(e) => { loadCityList({ "state": e.stateIdentifier }) }}
                                                            keyfield="name"
                                                            valuefield="stateIdentifier"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="zipCode"
                                                            label="Zip Code"
                                                            placeholder="Enter Your Company Zip Code"
                                                            requiredField
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Box marginTop="20px" display="flex" justifyContent="space-between">
                                                    <Button
                                                        className='btn btn-back'
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleBack}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        //type="submit"
                                                        className='btn btn-submit'
                                                        onClick={handleNext}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={!dirty || !isValid || values.addressLine1.length === 0}
                                                    >
                                                        Next
                                                    </Button>
                                                </Box>
                                            </div>
                                            </>
                                        )}
                                        {activeStep === 2 && (
                                            <>
                                            <div className='member-block-body'>
                                                <Typography variant="h4" align="center" marginBottom="30px">
                                                    Company Description
                                                </Typography>
                                                <Grid className="text-area" item xs={12}>
                                                    <FormikControl
                                                        control="textfield"
                                                        label="Company Details"
                                                        type="text"
                                                        variant="outlined"
                                                        requiredField
                                                        multiline
                                                        rows={4}
                                                        fullWidth
                                                        sx={{ m: 1 }}
                                                        name="companyDetails"
                                                        placeholder="Company Details"
                                                    />
                                                </Grid>
                                                <Box marginTop="20px" display="flex" justifyContent="space-between">
                                                    <Button
                                                        className='btn btn-back'
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleBack}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        className='btn btn-submit'
                                                        onClick={handleNext}
                                                        //type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={!dirty || !(isValid) || values.companyDetails.length === 0}
                                                    >
                                                        Next
                                                    </Button>
                                                </Box>
                                                </div>
                                            </>
                                        )}
                                        {activeStep === 3 && (
                                            <> <div className='member-block-body'>
                                                <Typography variant="h4" align="center" marginBottom="30px">
                                                    Company Social Profiles
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="instagramUrl"
                                                            label="Instagram URL"
                                                            placeholder="Enter Your Instagram URL"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="linkedinUrl"
                                                            label="LinkedIn URL"
                                                            placeholder="Enter Your LinkedIn URL"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="twitterUrl"
                                                            label="Twitter URL"
                                                            placeholder="Enter Your Twitter URL"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="facebookUrl"
                                                            label="Facebook URL"
                                                            placeholder="Enter Your Facebook URL"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikControl
                                                            control="textfield"
                                                            name="youtube"
                                                            label="YouTube URL"
                                                            placeholder="Enter Your YouTube URL"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Box marginTop="20px" display="flex" justifyContent="space-between">
                                                    <Button
                                                        className='btn btn-back'
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleBack}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        // type="submit"
                                                        className='btn btn-submit'
                                                        onClick={handleNext}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={!dirty || !(isValid)}
                                                    >
                                                        Next
                                                    </Button>
                                                </Box>
                                                </div>
                                            </>
                                        )}
                                        {activeStep === 4 && (
                                            <>
                                                <div className='member-block-body'>
                                                <Typography variant="h4" align="center" marginBottom="30px">
                                                    Membership Type
                                                </Typography>
                                                <FormikControl
                                                    className="radiobutton"
                                                    control="radiogroup"
                                                    label="Membership Type"
                                                    name="membershipType"
                                                    options={[
                                                        { key: "Founder Member", value: "founder" },
                                                        { key: "Exclusive Member", value: "exclusive" },
                                                    ]}
                                                    placeholder="Select Membership Type"
                                                    requiredField
                                                    row
                                                />
                                                <Box className="member-btn-one" marginTop="20px" display="flex" justifyContent="space-between">
                                                    <Button
                                                        className='btn btn-back'
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleBack}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        //type="submit"
                                                        className='btn btn-submit'
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleNext}
                                                        disabled={(!dirty || !(isValid)) || values.membershipType.length === 0}
                                                    >
                                                        Next
                                                    </Button>
                                                </Box>
                                                </div>
                                            </>
                                        )}
                                        {activeStep === 5 && (
                                            <>
                                                <div className='collll'>
                                                    <MembershipContactPerson memberlist={memberlist} setMemberList={setMemberList} />


                                                    {memberlist?.length > 0 ?
                                                        <>
                                                            <RECAPTCHA
                                                                sitekey={msgTypes.GOOGLE_CAPTCHA_KEY}
                                                                ref={recaptcha}
                                                                onChange={onCaptchaSubmit}
                                                                className='recaptcha-obj'
                                                            />

                                                            <Box className="btn-member" marginTop="20px" display="flex" justifyContent="space-between">
                                                                <Button
                                                                    className='btn btn-back'
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleBack}
                                                                >
                                                                    Back
                                                                </Button>
                                                                <Button
                                                                    className='btn btn-submit'
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={isSubmitting && (!dirty || !(isValid) || memberlist.length === 0) || !captchaMatched}
                                                                >
                                                                    Submit
                                                                </Button>

                                                            </Box>
                                                        </>

                                                        : ""}
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default MembershipForm;
