import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import './App.scss';
//import Login from './components/sharedComponent/login/Login';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { title } from 'constants/title';
import Home from 'screens/menu/home/Home';
import MembershipBenifits from 'screens/menu/membershipBenifits/MembershipBenifits';
import AboutUs from 'screens/menu/aboutUs/AboutUs';
import EventAndConferences from 'screens/menu/eventAndConference/EventAndConference';
import LandingPage from 'screens/sharedScreen/LandingPage';
import ContactUs from 'screens/menu/contactUs/ContactUs';
import Verticals from 'screens/menu/verticals/Verticals';
import PostEventGallery from 'screens/menu/postEventGallery/PostEventGallery';
import FAQs from 'screens/menu/faqs/FAQs';
import TermsAndConditions from 'screens/menu/terms-and-conditions/TermsAndConditions';
import PrivacyPolicies from 'screens/menu/privacyPolicies/PrivacyPolicies';
import CookiePolicies from 'screens/menu/cookiePolicies/CookiePolicies';
import Blog from 'screens/menu/blog/Blog';
import BlogDetails from 'screens/menu/blogDetails/BlogDetails';
import NewsAndMedia from 'screens/menu/news-and-media/NewsAndMedia';
import NewsAndMediaDetails from 'screens/menu/news-and-media-details/NewsAndMediaDetails';
import CSR from 'screens/menu/csr/CSR';
import CSRDetails from 'screens/menu/csrDetails/CSRDetails';
import Conferences from 'screens/menu/Conferences/conferences';
import MembershipForm from 'screens/menu/membershipBenifits/MembershipForm';

//import Blog from 'screens/blogs/Blog';

// const Dashboard = React.lazy(() => import('components/sharedComponent/dashboard/Dashboard'))



function App() {

  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();
  const preTitle = '1WN, One World One Network';

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    displayTitle();
  }, [location])

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "js/onpoint.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  function displayTitle() {
    const crumbArray = location.pathname.split('/');
    const endPoint = crumbArray[crumbArray.length - 1];
    const displayTitle = title.filter(item => {
      return item.key === endPoint;
    })
    setPageTitle(displayTitle[0] ? preTitle + ' - ' + displayTitle[0].value : preTitle);
  }

  return (
    // <ThemeProvider theme={theme}>
    // <AuthProvider>
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <script src="js/onpoint.js" type="text/javascript" />
          <title>{pageTitle}</title>
        </Helmet>
        <AppRoutes />
      </HelmetProvider>
    </div>
    // </AuthProvider>
    // </ThemeProvider>
  );
}

const SuspenseLayout = () => (
  <React.Suspense fallback={<>Loading...</>}>
    <Outlet />
  </React.Suspense>
);

function AppRoutes() {
  return (

    <>
      <Routes>
        <Route exact path='/' element={<LandingPage />}>
          <Route exact path='' element={<Home />} />
          <Route exact path='membership-benifits' element={<MembershipBenifits />} />
          <Route exact path='about-us' element={<AboutUs />} />
          <Route exact path='event-and-conference' element={<EventAndConferences />} />
          <Route exact path='contact' element={< ContactUs />} />
          <Route exact path='verticals' element={<Verticals />} />
          <Route exact path='post-event-gallery' element={<PostEventGallery />} />
          <Route exact path='faqs' element={<FAQs />} />
          <Route exact path='terms-and-conditions' element={<TermsAndConditions />} />
          <Route exact path='privacy-policies' element={<PrivacyPolicies />} />
          <Route exact path='cookie-policies' element={<CookiePolicies />} />
          <Route exact path='blog' element={<Blog />} />
          <Route exact path='blog-details' element={<BlogDetails />} />
          <Route exact path='news-and-media' element={<NewsAndMedia />} />
          <Route exact path='news-and-media-details' element={<NewsAndMediaDetails />} />
          <Route exact path='csr' element={<CSR />} />
          <Route exact path='csr-details' element={<CSRDetails />} />
          <Route exact path='conferences' element={<Conferences />} />
          <Route exact path='membership-registration' element={<MembershipForm />} />
          
        </Route>
        {/* <Route path="*" element={<Home />} /> */}
      </Routes >
    </>

  )
}

export default App;
