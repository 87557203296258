import { ErrorMessage, Field } from 'formik';
import React, { useState } from 'react'
import { DatePicker } from 'antd'
import MuiLabel from './MuiLabel';
import TextError from './TextError';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
const { RangePicker } = DatePicker;
const style = {
  width: '100%',
  height: "36px",
  border: "1px solid #c5c0c0",
  borderRadius: "4px"
}

export const AntdDateRangePicker = (props) => {
  const { startDate, endDate, label, values, ...rest } = props;
  const [dates, setDates] = useState(null);
  const [date1, setDate1] = useState(values[startDate]);
  const [date2, setDate2] = useState(values[endDate]);
  
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 31;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 31;
    return !!tooEarly || !!tooLate;
  };

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  
  return (
    <Field name={startDate} {...rest}>
      {({ field, form }) => {
        const { setFieldValue } = form;
        if(values[startDate].length>0){
          setDate1(values[startDate])
        }
        if(values[endDate].length>0){
          setDate2(values[endDate])
        }
        return (
          <React.Fragment>
            <MuiLabel {...props} />
            <RangePicker
              {...rest}
              {...field}
              id={startDate}
              name={startDate}
              className="custom-datepicker"
              popupClassName="custom-datepicker-dropdown"
              onChange={(values) => {
                if (values) {
                  setFieldValue(startDate, formatDate(values[0]))
                  setFieldValue(endDate, formatDate(values[1]))
                  if(props.onChangeClick)
                    props.onChangeClick('CST', [ formatDate(values[0]), formatDate(values[1]) ]);
                }else{
                  setFieldValue(startDate, "")
                  setFieldValue(endDate, "")
                } 
              }}
              value={field.value!=='' ? [dayjs(date1,'YYYY-MM-DD'), dayjs(date2,'YYYY-MM-DD')]:[null,null]}
              style={style}
              format={'DD-MM-YYYY'}
              inputReadOnly={true}
              disabledDate={disabledDate}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              onOpenChange={onOpenChange}
              changeOnBlur
             />
             <Box className='error-box'>
              <ErrorMessage name={startDate} component={TextError} />
             </Box>
          </React.Fragment>
        )
      }
      }
    </Field>
  )
}
