import { RouteType } from "constants/RouteType";
import { MembershipFormModel } from "model/screensModels/membershipRegistration/MembershipFormModel";
import { AuthModel } from "model/utilModels/AuthModel";
import { AuthService } from "services/auth/AuthService";

export class MembershipService {
    constructor() { }
  
    static addMembership = async (requestData: MembershipFormModel) => {
      const url = RouteType.MEMBERSHIP_REGISTER;
      const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestData))
      return response;
    };
}