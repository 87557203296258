import { Link } from "react-router-dom";
import Header from '../../sharedScreen/Header'
//import "react-multi-carousel/lib/styles.css";
import './ContactUs.scss';
import Footer from 'screens/sharedScreen/Footer';
import ContactEnquiry from "screens/sharedScreen/ContactEnquiry";
import Carousel from 'react-multi-carousel';
import SocialMediaIcon from "screens/sharedScreen/SocialMediaIcon";
import Testimonial from "../testimonial/Testimonial";

const ContactUs = () => {

    const supplyChainResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1700, min: 1200 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1200, min: 992 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

    return (
        <div>
            {/* <!--Page Header Start--> */}
            <section className="page-header member-section">
                <div className="page-header__bg contact-banner">
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Contact Us</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb list-unstyled">
                                {/* <li><a href="index.html">Home</a></li> */}
                                <li><Link to="/">Home</Link></li>
                                <li><span className="icon-angle-left"></span></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //   <!--Page Header End--> */}
            <section className="contact" id="contact">
                <div className="container">
                    <div className="heading text-center">
                        {/* <h2>Contact
                            <span> Us </span></h2> */}
                        <p>Embrace an invitation into a community marked by dynamic collaboration, robust efficiency,<br />and a client-centric ethos at One World Network.</p>
                    </div>
                    <div className='row contact-add-section'>
                        <div className='col-md-4 contact-margin'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>Radisson Blu Plaza Hotel,<br />
                                        Delhi Airport, New Delhi -110037</h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 contact-margin'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>IFZA Business Park,<br />
                                        Dubai Silicon Oasis, Dubai</h3>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 contact-margin'>
                            <div className='add-section'>
                                <div className='add--'>
                                    <div className='icon'>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <h3>511, 8W Building, DAFZA Dubai Airport Free Zone,<br />
                                        Dubai Airport, UAE .</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row contact-detail-sec">
                        <div className="col-md-5 position-relative">
                            <div className='contact-page'>
                                <div className="title">
                                    <h3>Contact detail</h3>
                                    <p> Becoming a member of One World Network <br /> means joining a community of dynamic, resilient, and results-driven freight forwarders. </p>
                                </div>
                                <div className="content">
                                    {/* <!-- Info-1 --> */}
                                    <div className="info">
                                        <div className='icon'><i className="fas fa-mobile-alt"></i></div>
                                        <h4 className="d-inline-block">For India and what’s app :
                                            <br />
                                            <span>+91-73037 33554</span></h4>
                                    </div>
                                    {/* <!-- Info-2 --> */}
                                    <div className="info">
                                        <div className='icon'><i className="fas fa-mobile-alt"></i></div>
                                        <h4 className="d-inline-block">For Dubai :
                                            <br />
                                            <span>+971-54 522 3903</span></h4>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">

                            <form className="form-margin">
                                {/* call contact enquiry form */}
                                <ContactEnquiry />
                            </form>
                        </div>
                    </div>
                </div>
            </section>

           <Testimonial />

            {/* <!--Why Are We Start--> */}
            <div className='our-mission-footer-form'>
                <section className="why-are-we ">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12'>
                                <div class="section-title text-center">
                                    <div class="border-center section-title__tagline-box">
                                        <span class="section-title__tagline">Join 1WN</span>
                                    </div>
                                    <h2 class="section-title__title">This ambitious vision is only achievable by</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="why-are-we__left">
                                    <div className="why-are-we__img">
                                        <img src="images/resources/global.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="why-are-we__right">

                                    {/* <div className="section-title text-left">
                                        <div className="section-title__tagline-box">
                                            <span className="section-title__tagline">This ambitious vision is only achievable by
                                                working </span>
                                        </div>
                                        <h2 className="section-title__title">Join 1WN
                                        </h2>
                                    </div> */}
                                    <p className="counter-one__right-text">As we continue to design this dynamic network we are delighted to invite you to transcend boundaries with the next-level networking experience. Join us and become an integral part of a community that redefines logistics, fosters collaboration, and shapes the future of global trade. Let’s connect and navigate through the complexities of the logistics industry and together create One World One Network!
                                    </p>
                                    <div className="why-are-we__img-2">
                                        <img src="images/resources/why-we-img-2.png" alt="" />
                                        <div className="why-are-we__year wow fadeInLeft" data-wow-delay="300ms">
                                            <h3>PROFESSIONAL BUSINESS SUPPORT</h3>
                                            <p>Association service involves the planning, implementation, and control of the efficient and effective movement and storage</p>
                                            <SocialMediaIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <!--Why Are We End--> */}
        </div>

    )
}

export default ContactUs