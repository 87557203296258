import { Link } from "react-router-dom";
// import Footer from "screens/sharedScreen/Footer";
// import Header from "screens/sharedScreen/Header";
// import EventAndConferencesForm from "./eventAndConferenceForm"
import EventRegistrationForm from "./EventRegistration";

const EventAndConferences = () => {
    return (
        <div>
            {/* Header Component Call   */}
            {/* <Header /> */}
            <div className="page-wrapper">
                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>

                {/* <!--Page Header Start--> */}
                <section className="page-header member-section">
                    <div className="page-header__bg event-conferences-banner">
                    </div>
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Event And Conferences</h2>
                            <div className="thm-breadcrumb__box">
                                <ul className="thm-breadcrumb list-unstyled">
                                    {/* <li><a href="index.html">Home</a></li> */}
                                    <li><Link to="/">Home</Link></li>
                                    <li><span className="icon-angle-left"></span></li>
                                    <li>Event And Conferences</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* //   <!--Page Header End--> */}


                {/* <!-- Start of Blog Details section
	============================================= --> */}
                <section id="ft-blog-feed-details" class="ft-blog-feed-details-section eventpagepadding">
                    <div class="container">
                        <div class="ft-blog-feed-details-content">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="blog-details-img-text-wrapper">
                                        <div class="blog-details-img position-relative">
                                            <img src="images/resources/event-conference.png" alt="" />
                                        </div>
                                        {/* <div class="ft-blog-details-item">
                                            <div class="blog-details-text headline">
                                                <div class="ftd-blog-meta-2  position-relative text-capitalize">
                                                    <a href="#"><i class="far fa-clock"></i> September 12, 2020</a>
                                                    <a href="#"><i class="far fa-user"></i> by admin</a>
                                                    <a href="#"><i class="fas fa-tags"></i> Education</a>
                                                </div>
                                                <article>
                                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum The man, who is in a stable condition
                                                    inhospital, has "potentially life-changing injuries" after the overnight attack in Garvagh, County Lonodonderry. He was shot in the arms and legs."What sort of men would think.” A reader will be distracted by the readable content.
                                                </article>
                                                <article>
                                                    "Every child has the right to feel safe and protected in their own home - how is this poor child going to sleep tonight or in coming nights? What are the long term effects on her going to be?"
                                                </article>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div class="ft-blog-details-item">
                                        <div class="blog-details-text headline">
                                            <div class="ftd-blog-meta-2  position-relative text-capitalize">
                                                <a href="#"><i class="far fa-clock"></i> September 12, 2020</a>
                                                {/* <a href="#"><i class="far fa-user"></i> by admin</a> */}
                                                <a href="#"><i class="fas fa-location-arrow"></i> Noida</a>
                                            </div>
                                            <article className="artical-text">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum The man, who is in a stable condition
                                                inhospital, has "potentially life-changing injuries" after the overnight attack in Garvagh, County Lonodonderry. He was shot in the arms and legs."What sort of men would think.” A reader will be distracted by the readable content.
                                            </article>
                                            <article className="artical-text">
                                                "Every child has the right to feel safe and protected in their own home - how is this poor child going to sleep tonight or in coming nights? What are the long term effects on her going to be?"
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Start of Blog Details section
	============================================= -->	 */}


                {/* eventAndConferenceForm */}

                {/* <EventAndConferencesForm /> */}
                <EventRegistrationForm />

                {/* Footer Component Call */}

                {/* footer-form */}

                {/* <Footer /> */}
            </div>
        </div>
    )

}

export default EventAndConferences;