import React from 'react'

const FixedButtons = () => {
    return (
        <div>
            {/* Click to top */}
            {/* <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fas fa-arrow-up"></i></a> */}

            {/* join us button */}

            {/* <div id="fixed-btn">
                <div>
                    <a href="membership-benifits" class="fixed-catalog">
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn">
                                <span class="catalog-text">join Us</span></span>
                        </div>
                    </a>
                </div>
                <div><a href="https://wa.me/+917303733554" target="_blank" title="Click to connect on whatsapp"
                    class="whatsapp-icon-img">
                    <img alt="waapLogo"
                        src="images/icon/whatsapp-logo.png" class="whatsappWidth" /></a>
                </div>
            </div> */}

            {/* join us button */}

            <div id="fixed-btn">

                <div>
                    <a href="membership-benifits" class="fixed-catalog">
                        <div id="downloadCatalog" type="button" class="catalog-res">
                            <span class="catalog-btn">
                                <span class="catalog-text">join Us</span></span>
                        </div>
                    </a>
                </div>
                <div><a href="https://wa.me/+917303733554" target="_blank" title="Click to connect on whatsapp"
                    class="whatsapp-icon-img">
                    <img alt="waapLogo"
                        src="images/icon/whatsapp-logo.png" class="whatsappWidth" /></a>
                </div>
                <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fas fa-arrow-up"></i></a>
            </div>
        </div>
    )
}

export default FixedButtons
