import { msgTypes } from "constants/msgTypes";
import { RouteType } from "constants/RouteType";
import { EventRegistrationModel } from "model/screensModels/eventRegistration/EventRegistrationModel";
import { AuthService } from "services/auth/AuthService";

export class EventService {
    constructor() { }
  
    static eventRegistration = async (requestData: EventRegistrationModel) => {
      const url = RouteType.EVENT_REGISTRATION;
      const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestData))
      return response;
    };

    static getEventList = async () => {
      const url = RouteType.GET_EVENT;
      const response = await AuthService.postCryptoRequest(url, msgTypes.EMPTY_STR)
      return response;
    };


}