import React, {useState, useEffect, useLayoutEffect }from 'react'
import { Link } from 'react-router-dom'
import { NavLink, useLocation } from 'react-router-dom';
const Header = () => {
    const location = useLocation();
    const [isSticky, setIsSticky] = useState(false);

    // Function to handle scroll event
    const handleScroll = () => {
        if (window.scrollY > 100) { // adjust the value as per your layout
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className={isSticky ? "stricky-fixed" : ""}>
            <div className="page-wrapper ">
                <header className="main-header">
                    <nav className="main-menu">
                        <div className="main-menu__wrapper">
                            <div className="container">
                                <div className="main-menu__wrapper-inner">
                                    <div className="main-menu__left">
                                        <div className="main-menu__logo">
                                            <a href="/"><img src="images/resources/logo-1wn.png" alt=""></img>
                                            </a>
                                        </div>
                                        <div className="main-menu__main-menu-box">
                                            <a href="#" className="mobile-nav__toggler"><i className="fa fa-bars"></i></a>
                                            <ul className="main-menu__list">
                                                <li className={location.pathname === "/" ? "active" : ""}>
                                                    <NavLink exact to="/" activeClassName="active-link">Home</NavLink>
                                                </li>
                                                 <li className={location.pathname === "/about-us" ? "active" : ""}>
                                                    <NavLink exact to="/about-us" activeClassName="active-link">About</NavLink>
                                                    {/* <ul>
                                                        <li><a href="mission.html">Our Mission</a></li>
                                                        <li><Link to="/our-mission">Our Mission</Link></li>
                                                        <li><a href="#">Our Vision</a></li>
                                                    </ul> */}
                                                </li>
                                                {/* <li>
                                                    <NavLink to="/verticals" activeClassName="active-link">Verticals</NavLink>
                                                </li> */}
                                                {/* <li className="dropdown">
                                                    <NavLink to="/news-and-media" activeClassName="active-link">News & Media</NavLink>
                                                    <ul>
                                                        <li><Link to="#">Affiliations & Accreditations</Link></li>
                                                    </ul>
                                                </li> */}
                                                <li className={location.pathname === "/membership-benifits" ? "active" : "dropdown"}>
                                                    {/* <a href="/news-and-media">News & Media</a> */}
                                                    <NavLink to="/membership-benifits" activeClassName="active-link">Membership Benefits</NavLink>
                                                    <ul>
                                                        {/* <li><a href="mission.html">Our Mission</a></li> */}
                                                        <li className={location.pathname === "/membership-benifits" ? "active" : ""}><Link to="/membership-registration">Membership Registration</Link></li>
                                                    </ul>
                                                </li>
                                                {/* <li>
                                                    <NavLink to="/blog" activeClassName="active-link">Blogs</NavLink>
                                                </li> */}
                                                <li className={location.pathname === "/contact" ? "active" : ""}>
                                                    <NavLink exact to="/contact" activeClassName="active-link">Contact Us</NavLink>
                                                </li>
                                                {/* <li className="dropdown">
                                                    <a href='javascript:void(0)'>Event & Conferences</a>
                                                    <ul>
                                                        <li><Link to="csr">CSR</Link></li>
                                                        <li><NavLink to="/conferences" activeClassName="active-link">1WN Conferences</NavLink></li>
                                                        <li><NavLink to="/post-event-gallery" activeClassName="active-link">Post Event Gallery</NavLink></li>
                                                        <li><Link to="/event-and-conference">Event & Conferemces Registearion</Link></li>
                                                    </ul>
                                                  
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="main-menu__right">
                                        <div className="main-menu__search-nav-sidebar-btn-box">
                                            {/* <div className="main-menu__search-box">
                                                <a href="#" className="main-menu__search search-toggler fas fa-search"></a>
                                            </div> */}
                                            <div className="main-menu__nav-sidebar-icon">
                                             </div>
                                            <div className="main-menu__btn-box">
                                                {/* <a href="#" className="thm-btn main-menu__btn">Join Now<span></span></a> */}
                                                <Link to={"/membership-registration"} className="thm-btn main-menu__btn">Join Now<span></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </div>
    )
}

export default Header
