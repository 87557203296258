export class ResponseModel {
    statusCode: string;
    statusMsg: string;
    statusRemark: string;
    data: any;
    displayAlert: boolean;


    constructor() {
        this.statusCode = '';
        this.statusMsg = '';
        this.statusRemark = '';
        this.data = '';
        this.displayAlert = false;
    }
}